// 商品管理
export default [
  {
    path: "/productMgt",
    meta: { title: "商品管理" },
    component: () => import("../views/productMgt/index.vue"),
    // redirect: "/productMgt/productList",
    children: [
      {
        name: "productList",
        path: "/productMgt/productList",
        meta: { title: "商品列表", isMenu: true },
        component: () => import("../views/productMgt/productList.vue"),
      },
      {
        name: "apply",
        path: "/productMgt/detail/apply",
        meta: { title: "申请新商品" },
        component: () => import("../views/productMgt/detail/apply.vue"),
      },
       {
        name: "edit",
        path: "/productMgt/detail/edit",
        meta: { title: "编辑" },
        component: () => import("../views/productMgt/detail/edit.vue"),
      },
      {
        name: "productReview",
        path: "/productMgt/productReview",
        meta: { title: "商品审核", isMenu: true },
        component: () => import("../views/productMgt/productReview.vue"),
      },
      {
        name: "productStatistics",
        path: "/productMgt/productStatistics",
        meta: { title: "商品统计", isMenu: true },
        component: () => import("../views/productMgt/productStatistics.vue"),
      },
      {
        name: "purchasePackage",
        path: "/productMgt/purchasePackage",
        meta: { title: "进货套餐管理", isMenu: true },
        component: () => import("../views/productMgt/purchasePackage.vue"),
      },
      {
        name: "shelfLifeWarning",
        path: "/productMgt/shelfLifeWarning",
        meta: { title: "保质期预警", isMenu: true },
      },
      {
        name: "supplierAlert",
        path: "/productMgt/shelfLifeWarning/supplierAlert",
        meta: { title: "供应商预警", isMenu: true },
        component: () => import("../views/productMgt/supplierAlert.vue"),
      },
      {
        name: "supermarketAlert",
        path: "/productMgt/shelfLifeWarning/supermarketAlert",
        meta: { title: "商超预警", isMenu: true },
        component: () => import("../views/productMgt/supermarketAlert.vue"),
      },
      {
        name: "warningSetting",
        path: "/productMgt/warningSetting",
        meta: { title: "保质期预警设置", isMenu: true },
        component: () => import("../views/productMgt/warningSetting.vue"),
      },
      {
        name: "typeList",
        path: "/productMgt/typeList",
        meta: { title: "分类列表", isMenu: true },
        component: () => import("../views/productMgt/typeList.vue"),
      },
      {
        name: "typeReview",
        path: "/productMgt/typeReview",
        meta: { title: "分类审核", isMenu: true },
        component: () => import("../views/productMgt/typeReview.vue"),
      },
      {
        name: "myApplication",
        path: "/productMgt/myApplication",
        meta: { title: "我的申请", isMenu: true },
        component: () => import("../views/productMgt/myApplication.vue"),
      },
      {
        name: "productDetail",
        path: "/productMgt/detail/productDetail",
        meta: { title: "商品详情" },
        component: () => import("../views/productMgt/detail/productDetail.vue"),
      },
      {
        name: "commodityPackage",
        path: "/productMgt/detail/commodityPackage",
        meta: { title: "商品包管理" },
        component: () => import("../views/productMgt/detail/commodityPackage.vue"),
      },
      {
        name: "typeDetail",
        path: "/productMgt/detail/typeDetail",
        meta: { title: "分类详情" },
        component: () => import("../views/productMgt/detail/typeDetail.vue"),
      },
      {
        name: "serviceCharge",
        path: "/productMgt/serviceCharge",
        meta: { title: "供货价管理", isMenu: true },
        component: () => import("../views/productMgt/serviceCharge.vue"),
      },
      {
        name: "serviceSetup",
        path: "/productMgt/detail/serviceSetup",
        meta: { title: "批量设置供货价费率" },
        component: () => import("../views/productMgt/detail/serviceSetup.vue"),
      },
      {
        name: "serviceInfo",
        path: "/productMgt/detail/serviceInfo",
        meta: { title: "供货价查看" },
        component: () => import("../views/productMgt/detail/serviceInfo.vue"),
      },
    ],
  },
];
