<!-- 库存审核 -->
<template>
  <div class='list'>
    <div class="globle_border" v-loading="loading">

      <div class="search">
        <el-date-picker v-model="datetime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="handleSelectData" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-select v-model="status" clearable placeholder="审核进度" @change="onSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="area" placeholder="搜索地区" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="campus" placeholder="搜索校区" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="number" placeholder="搜索申请单号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="search" placeholder="搜索商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="city" label="地区" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="schoolName" label="校区" show-overflow-tooltip></el-table-column>
          <el-table-column prop="shopName" label="商超名称" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactMan" label="联系人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactTel" label="联系电话" show-overflow-tooltip></el-table-column>
          <el-table-column prop="applyNum" label="申请单号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createdTime" label="申请时间" min-width=180></el-table-column>
          <el-table-column prop="" label="审核进度">
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.auditStatus == 0 ? '未完成' : '已完成' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="danger" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.auditStatus == 0">审核</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.auditStatus == 1">查看</el-button>
              <!-- <el-button class="btn" type="danger" size="small" plain @click="showDetail(scope.row)" >审核</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)" >查看</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>

export default {

  data() {
    return {
      loading: false,
      search: '',
      area: '',//地区
      campus: '',//校区
      number: '',//单号
      datetime: [],
      tableData: [
        {}
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      options: [{
        value: '0',
        label: '未完成'
      }, {
        value: '1',
        label: '已完成'
      },],
      status: '',

    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("inventoryReview"))
    this.currentPage = obj?.currentPage
    this.datetime = obj?.datetime
    this.status = obj?.status
    this.area = obj?.area
    this.campus = obj?.campus
    this.number = obj?.number
    this.search = obj?.search
    this.$saveOrUpdateCache("inventoryReview", {})
    this.getSupplierInfoPageList()
  },
  methods: {
    // 时间搜索
    handleSelectData(value) {
      this.datetime = value;
      this.onSearch();
    },
    // 商超列表
    getSupplierInfoPageList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        auditStatus: this.status,//状态
        city: this.area,//地区
        schoolName: this.campus,//校区
        applyNum: this.number,//单号
        shopName: this.search,//商超
      };
      // 时间区间
      if (this.datetime && this.datetime.length === 2) {
        params.startTime = this.datetime[0];
        params.endTime = this.datetime[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }
      // console.log(params);
      this.$axios.get(this.$api.auditPageList, {
        params
      }).then((res) => {
        this.tableData = res.data.result.list;
        this.totalItemsCount = res.data.result.totalCount;
      }).catch(() => {
      });
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e;
      this.getSupplierInfoPageList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e;
      this.getSupplierInfoPageList();
    },
    // 查看/审核
    showDetail(row) {
      let obj = {
        currentPage: this.currentPage,
        datetime: this.datetime,
        status: this.status,
        area: this.area,
        campus: this.campus,
        number: this.number,
        search: this.search,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("inventoryReview", obj)
      this.$router.push({ path: '/supermarket/detail/inventoryDetail', query: { id: row.id }, });
    },

  },
}
</script>

<style scoped lang='scss'>
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    // &:nth-child(n) {
    //   margin-bottom: 20px;
    // }
    ::v-deep .el-input {
      margin-left: 20px;
      width: 200px;
      margin-bottom: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>