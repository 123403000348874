<!-- 骑手列表 -->

<template>
  <div class="bikerList">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="selectValue" placeholder="选择状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="nameValue" placeholder="搜索骑手姓名" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="schoolValue" placeholder="搜索学校名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="riderNum" label="骑手编号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="riderName" label="姓名"></el-table-column>
          <el-table-column show-overflow-tooltip prop="idCard" label="身份证号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="schoolName" label="学校名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="studentId" label="学号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="phoneNum" label="手机号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="提交时间"></el-table-column>
          <el-table-column label="状态" header-align="center" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2"
                @change="changeStatus(scope.row)"
                :disabled="scope.row.status == 0 || scope.row.status == 2"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="审核状态">
            <template slot-scope="scope">
              <span>{{ getStatusText(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="180">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain v-if="scope.row.status == 0"
                @click="handlePass(scope.row)">通过</el-button>
              <el-button size="small" class="btn" type="danger" plain v-if="scope.row.status == 0"
                @click="handleUnpass(scope.row)">不通过</el-button>
              <el-button size="small" class="btn" type="primary" plain v-if="scope.row.status != 0"
                @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 审核不通过原因弹窗-->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" width="1244px" :before-close="unpassResonDialogClose">
      <el-form :model="dialogRuleForm" :rules="dialogRules" ref="dialogRuleForm" label-width="160px"
        class="demo-ruleForm">
        <el-row class="dialog_row">
          <el-col :span="24">
            <el-form-item label="审核不通过原因" prop="unpassReason">
              <el-input v-model="dialogRuleForm.unpassReason" placeholder="请输入" type="textarea" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="unpassResonDialogClose">取 消</el-button>
        <el-button type="primary" @click="unpassResonDialogSubmitForm('dialogRuleForm')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 下拉框数据
      selectValue: '', // 状态
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '2',
        label: '不通过'
      }, {
        value: '1',
        label: '审核通过-账号启用'
      },
      {
        value: '3',
        label: '审核通过-账号停用'
      },
      ],
      nameValue: '', // 骑手姓名
      schoolValue: '', // 学校名称
      // 表格数据
      tableData: [],
      ruleForm: {},
      dialogVisible: false, // 不通过弹窗
      dialogRuleForm: {
        userId: '',
        unpassReason: '',
      },
      dialogRules: {
        unpassReason: [{ required: true, message: '请输入不通过原因', trigger: 'blur' }],
      },
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("bikerList"))
    this.selectValue = obj?.selectValue
    this.nameValue = obj?.nameValue
    this.schoolValue = obj?.schoolValue
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("bikerList", {})
    this.getRiderUserList();
  },
  methods: {
    getStatusText(status) {
      switch (status) {
        case '0':
          return "待审核";
        case '1':
          return "审核通过";
        case '2':
          return "审核不通过";
        case '3':
          return "审核通过";
        default:
          return " ";
      }
    },
    // 获取列表
    getRiderUserList() {
      this.loading = true;
      let params = {
        riderName: this.nameValue || null, //骑手姓名
        schoolName: this.schoolValue || null, //学校姓名
        status: this.selectValue || null, //审核状态
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(this.$api.getRiderUserList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            let list = res.data.result.list.map((e) => {
              return {
                ...e,
                switch: e.status == "1" ? true : false,
              };
            });
            this.tableData = list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSelect(value) {
      this.clearData();
      this.selectValue = value;
      this.getRiderUserList();
    },
    changeStatus(row) {
      const originalSwitch = row.switch;
      this.$confirm(`是否切换状态？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$axios.put(this.$api.auditingRiderUserStatus, {
          userId: row.userId,
          status: row.switch ? "1" : "3",
        }).then(() => {
          this.$set(row, "switch", originalSwitch);
          this.$message.success("操作成功");
          this.getRiderUserList();
        }).catch(() => {
          this.$set(row, "switch", !originalSwitch);
          this.$message.error("操作失败");
          this.getRiderUserList()
        });
      }).catch(() => {
        this.$set(row, "switch", !originalSwitch);
        this.getRiderUserList()
      });
    },
    //  通过
    handlePass(row) {
      this.$confirm('确定该申请通过？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(this.$api.auditingRiderUserStatus, {
          userId: row.userId,
          status: 1,
        }).then(() => {
          this.getRiderUserList();
          this.$message.success('通过成功！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    // 不通过
    handleUnpass(row) {
      this.dialogVisible = true;
      this.dialogRuleForm.userId = row.userId
    },
    // 审核不通过弹窗-确定
    unpassResonDialogSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.put(this.$api.auditingRiderUserStatus, {
            userId: this.dialogRuleForm.userId,
            status: 2,
            reason: this.dialogRuleForm.unpassReason
          }).then(() => {
            this.getRiderUserList();
            this.$message.success('审核成功！')
          })
          this.unpassResonDialogClose();
        } else {
          return false;
        }
      });
    },
    // 关闭审核不通过弹窗
    unpassResonDialogClose() {
      this.dialogVisible = false;
      this.dialogRuleForm = {};
      // 重置校验
      this.$refs.dialogRuleForm.resetFields();
    },
    // 查看
    handleCheck(row) {
      let obj = {
        currentPage: this.currentPage,
        selectValue: this.selectValue,
        nameValue: this.nameValue,
        schoolValue: this.schoolValue,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("bikerList", obj)
      this.$router.push({
        path: 'detail/riderDetail',
        query: {
          userId: row.userId
        }
      });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getRiderUserList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getRiderUserList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getRiderUserList();
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 8) {
        if (row.status == '0') {
          return { color: 'red' }
        }
      }
    },
  }

}



</script>

<style scoped lang="scss">
.bikerList {


  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 280px;
      margin-left: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;

    }
  }

  // 审核不通过原因弹窗
  ::v-deep .el-textarea__inner {
    resize: none;
    background-color: #f2f2f2;
    border-radius: 10px;
    height: 130px;
  }

  ::v-deep .el-input__count {
    background-color: #f2f2f2;
  }
}
</style>
