<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <div class="input-box">
          <el-select v-model="filterStatus" placeholder="请选择状态" @change="onScreenData" clearable>
            <el-option v-for="item in filterOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="input-box">
          <el-select v-model="bizType" placeholder="请选择来源" @change="onScreenData" clearable>
            <el-option v-for="item in bizTypeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="input-box">
          <el-input v-model="barCode" @change="onScreenData" placeholder="搜索商品条形码" clearable
            suffix-icon="el-icon-search"></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="originValue" @change="onScreenData" placeholder="搜索申请供应商/商超" clearable
            suffix-icon="el-icon-search"></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="productName" @change="onScreenData" placeholder="搜索商品名称" clearable
            suffix-icon="el-icon-search"></el-input>
        </div>
        <div class="input-box">
          <el-button type="primary" @click="handleReviewAll" :disabled="multipleSelection.length == 0">一键审核</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" v-loading="dataLoding"
          @selection-change="handleSelectionChange" ref="tableRef" row-key="id">
          <el-table-column type="selection" width="55" :selectable="row => row.chooseStatus == 1"
            :reserve-selection="true"></el-table-column>
          <el-table-column prop="number" label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" min-width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :title="scope.row.productName">{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="分类名称" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="factoryName" label="厂家" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="costPrice" label="供货价(元)" show-overflow-tooltip width="90"></el-table-column>
          <el-table-column prop="suggestPrice" label="建议零售价(元)" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="bizType" label="来源" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.bizType === 1 ? "供应商" : scope.row.bizType === 2 ? "商超" : scope.row.bizType === 3 ? "总后台" :
                  "-" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="bizName" label="申请供应商/商超" show-overflow-tooltip width="150"></el-table-column>
          <el-table-column prop="" label="商品图片" min-width="120" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <el-image class="busilicense" style="width: 80px; height: 58px"
                :src="scope.row.urlList && scope.row.urlList.length > 0 ? scope.row.urlList[0] : ''" fit="cover"
                @mouseover="handleCommand(scope.row.urlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="申请时间" width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :style="`color:${scope.row.auditStatus == '待审核' ? '#EB2500' : ''}`">
                {{ scope.row.auditStatus }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="auditTime" label="审核时间" width="160" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="200"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="jump(scope.row)"
                v-if="scope.row.auditStatus !== '待审核'">查看</el-button>
              <template v-if="scope.row.auditStatus == '待审核'">
                <el-button class="btn" type="primary" plain @click="handConfirm(scope.row, true)">通过</el-button>
                <el-button class="btn" type="danger" plain @click="handleDialog(scope.row, false)">不通过</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 编辑 -->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancelAll">
      <el-form :model="ruleForm" ref="ruleForm" label-width="160px" :show-message="false">
        <el-row>
          <el-col :span="20">
            <el-form-item label="审核不通过原因" prop="auditMark" required>
              <el-input v-model="ruleForm.auditMark" placeholder="请输入" type="textarea" maxlength="20" show-word-limit
                :autosize="{ minRows: 6, maxRows: 6 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <!-- :loading="submitLoding" -->
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量 -->
    <el-dialog title="批量审核" :visible.sync="dialogAll" :before-close="onCancelAll">
      <el-form :model="ruleFormAll" ref="ruleFormAll" :rules="ruleFormAllRules" label-width="200px"
        class="demo-ruleForm">
        <el-form-item label="请选择">
          <el-radio v-model="ruleFormAll.auditStatus" label="1">审核通过</el-radio>
          <el-radio v-model="ruleFormAll.auditStatus" label="2">审核不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核不通过原因" prop="auditMark" v-show="ruleFormAll.auditStatus == 2">
          <el-input type="textarea" placeholder="请输入内容" v-model="ruleFormAll.auditMark" maxlength="200" show-word-limit
            :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancelAll">取 消</el-button>
        <el-button type="primary" @click="submitFormAll('ruleFormAll')">确 定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false
    }
      " :url-list="urlList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  name: "productReview",
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      dataLoding: false,
      filterStatus: "",
      filterOptions: [
        {
          label: "待审核",
          value: "0",
        },
        {
          label: "审核通过",
          value: 1,
        },
        {
          label: "审核不通过",
          value: 2,
        },
      ],
      bizTypeOptions: [
        {
          label: "供应商",
          value: 1,
        },
        {
          label: "商超",
          value: 2,
        },
        {
          label: "总后台",
          value: 3,
        },
      ],
      urlList: [], //图片
      showViewer: false, // 显示查看器、
      bizType: "", //来源
      productName: "",
      barCode: "",
      originValue: "", // 搜索申请供应商/商超
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 弹窗
      isAdd: false,
      dialogVisible: false,
      typeOptions: [],
      ruleForm: {
        auditMark: "",
      },
      // submitLoding: false,

      // 一键审核
      dialogAll: false,
      multipleSelection: [],
      ruleFormAll: {
        auditStatus: "1",
        auditMark: null,
      },
      ruleFormAllRules: {
        auditMark: [
          {
            required: true,
            message: "请填写审核不通过理由",
            trigger: "blur",
          },
        ],
      },
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productReview"))
    this.filterStatus = obj?.filterStatus
    this.bizType = obj?.bizType
    this.barCode = obj?.barCode
    this.originValue = obj?.originValue
    this.productName = obj?.productName
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productReview", {})
    this.getProductList()
  },
  methods: {
    getProductList() {
      this.dataLoding = true
      this.$axios
        .get(this.$api.productExamineList, {
          params: {
            bizType: this.bizType || null, //来源
            productName: this.productName || null,
            barCode: this.barCode || null,
            bizName: this.originValue || null, // 搜索申请供应商/商超
            page: this.currentPage,
            pageSize: this.pageSize,
            auditStatus: this.filterStatus || null,
          },
        })
        .then(res => {
          this.dataLoding = false
          let result = res.data.result
          this.tableData = result.list.map(t =>
            Object.assign(t, {
              auditStatus: this.filterOptions.find(f => f.value == t.auditStatus).label,
            })
          )
          this.totalItemsCount = result.totalCount
        })
    },
    // 一键审核
    // 关闭对话框
    onCancelAll() {
      this.resetForm()
      this.ruleFormAll.auditMark = ""
      this.dialogAll = false
      this.ruleFormAll.auditStatus = "1"
    },
    // 重置表单
    resetForm() {
      this.$refs.ruleFormAll.resetFields()
      this.$refs.tableRef.clearSelection()
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.multipleSelection = this.multipleSelection.map(item => item.id)
      console.log("选中", this.multipleSelection)
    },
    // 批量提交
    submitFormAll() {
      // console.log('批量', this.multipleSelection);
      this.$axios
        .post(this.$api.batchAuditProduct, {
          auditMark: this.ruleFormAll.auditMark,
          auditStatus: this.ruleFormAll.auditStatus,
          idList: this.multipleSelection,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message({
              message: res.data.desc,
              type: "success",
            })
            this.getProductList()
            this.onCancelAll()
          }
        })
    },
    handleReviewAll() {
      this.dialogAll = true
    },
    onCancel() {
      this.$refs["ruleForm"].resetFields()
      this.dialogVisible = false
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getProductList()
    },
    // 筛选
    onScreenData() {
      this.tableData = []
      this.currentPage = 1
      this.getProductList()
    },
    // 查看
    jump(row) {
      let obj = {
        currentPage: this.currentPage,
        filterStatus: this.filterStatus,
        bizType: this.bizType,
        barCode: this.barCode,
        originValue: this.originValue,
        productName: this.productName,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productReview", obj)
      let { id } = row
      this.$router.push({
        name: "productDetail",
        query: {
          id,
        },
      })
    },
    // 审核弹窗
    handleDialog(item) {
      // console.log('审核弹窗', item);
      Object.assign(this.ruleForm, {
        id: item.id,
      })
      this.dialogVisible = true
    },
    handConfirm(item, flag) {
      this.$confirm("确定该商品通过？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.handleAuditing(item, flag)
      })
    },
    // 弹窗表单提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return this.$message.error("请输入原因")
        }
        this.handleAuditing(this.ruleForm, false)
      })
    },
    // 审核提交
    handleAuditing(item, flag) {
      // this.submitLoding = true;
      this.$axios
        .post(this.$api.productListExamine, {
          id: item.id,
          auditStatus: flag ? 1 : 2,
          auditMark: item.auditMark || null,
        })
        .then(res => {
          // this.submitLoding = false;
          if (res.data.code == 100) {
            this.$message.success("审核成功")
            if (!flag) this.onCancel()
            this.getProductList()
          } else {
            this.$message.error("审核失败")
          }
        })
    },
    // 预览图片
    handleCommand(url) {
      this.urlList = url
      this.showViewer = true
    },
    // 关闭查看器
    // closeViewer() {
    //   this.showViewer = false
    // },
  },
}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  .input-box {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

.el-textarea {
  width: 86%;

  ::v-deep .el-input__count {
    background: transparent !important;
  }
}
</style>
