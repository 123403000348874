<template>
  <div class="main" v-loading="loading">
    <back />
    <!-- 穿梭框 -->

    <div v-if="type == 1|| type == 2 ">
      <div style="display: flex; justify-content: flex-start; font-weight: bold;margin-bottom: 10px;">{{ type == 2?'选择供应商':'选择商超' }}</div>
      <div style="display: flex;justify-content: space-around; " v-if="type == 2 ">
        <el-transfer @change="ontransfer" filterable :filter-method="filterMethod" filter-placeholder="请输入供应商名称" v-model="targetKeys" :data="supplierList" :titles="['选择供应商','已选供应商']">
        </el-transfer>
      </div>
      <div style="display: flex;justify-content: space-around; " v-if=" type == 1 ">
        <el-transfer @change="ontransfer" filterable :filter-method="filterMethod" filter-placeholder="请输入商超名称" v-model="targetKeys" :data="supplierList" :titles="['选择商超','已选商超']">
        </el-transfer>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <div style="font-weight: bold;margin-bottom: 10px;">费率设置</div>
      <div style="display: flex; align-items: center;">
        <div style="margin-right: 20px;">类型：</div>
        <div v-if="type == 1|| type == 2">
          <el-radio v-model="radio" :label="item.label" v-for="(item,index) in radios1" :key="index" @change="handleClick">{{item.name}}</el-radio>
        </div>
        <div v-else>
          <el-radio v-model="radio" :label="item.label" v-for="(item,index) in radios" :key="index" @change="handleClick">{{item.name}}</el-radio>
        </div>
      </div>
    </div>
    <div class="search-box">
      <div>
        <el-checkbox v-model="checked" @change="onCheckbox" v-if="radio != '4'"></el-checkbox>
        <span style="margin-left: 10px;">批量设置技术服务费率(%)：</span>
      </div>
      <!-- <el-input placeholder="请输入" v-model.number="input" @input="handleInput2" min="0" max="100">
        <i slot="suffix"> %</i>
      </el-input> -->
      <el-input-number v-model.number="input" :precision="2" :step="0.1" :min="0" :max="100"></el-input-number>
    </div>
    <!-- 搜索框 -->
    <div v-if="radio!=4">
      <div class="search-right">
        <div class="input-box">
          <!-- <el-input v-model="Rate" placeholder="是否设置费率"  clearable></el-input> -->
          <el-select v-model="techFeeRateStatus" placeholder="是否设置费率" @change="onScreenData" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input-box" v-if="radio=='1'">
          <el-input v-model="barCode" placeholder="搜索商品条形码" @change="onScreenData" clearable></el-input>
        </div>
        <!-- <div class="input-box" v-if="radio=='1'">
          <el-input v-model="serialId" placeholder="搜索商品编号" @change="onScreenData" clearable></el-input>
        </div> -->
        <div class="input-box" v-if="radio=='2'">
          <el-input v-model="brand" placeholder="搜索品牌名称" @change="onScreenData" clearable></el-input>
        </div>
        <div class="input-box" v-if="radio=='1'">
          <el-input v-model="productName" placeholder="搜索商品名称" @change="onScreenData" clearable></el-input>
        </div>

        <div class="input-box" v-if="radio=='3'">
          <el-input v-model="firstCategoryName" placeholder="搜索一级类目" @change="onScreenData" clearable></el-input>
        </div>
        <div class="input-box" v-if="radio=='3'">
          <el-input v-model="secondCategoryName" placeholder="搜索二级类目" @change="onScreenData" clearable></el-input>
        </div>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="450" @selection-change="handleSelectionChange" ref="multipleTable">
          <el-table-column type="selection" width="55" :reserve-selection="false">
          </el-table-column>
          <el-table-column prop="number" label="序号" width=''>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>

          <template v-for="(item,index) in newtableLabel">
            <el-table-column :prop="item.prop" :label="item.label" :key="index"></el-table-column>
          </template>
          <el-table-column prop="feeRate" label="技术服务费率（%）" align="left" :width="radio == 2?'':''">
            <template slot-scope="scope">
              <el-input-number v-model.number="scope.row.feeRate" :precision="2" :step="0.1" :min="0" :max="100" :disabled="scope.row.disabled" @change="onblur(scope.row)"></el-input-number>
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" header-align="center" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button class="btn" type="primary" size="small" plain @click="handleIncrease(scope.row,1)">增加</el-button>
            <el-button class="btn" type="primary" size="small" plain @click="handleIncrease(scope.row,2)" :disabled="!scope.row.quantity>0">减少</el-button>
            <el-button class="btn" type="danger" size="small" plain @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column> -->
        </el-table>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
      </el-pagination>
    </div>
    <div style="text-align: center;margin-top: 15px; display: flex;align-items: center; justify-content: center;    flex-direction: column;">
      <div style="color: red;margin-bottom: 10px;" v-if="radio != '4'">* 温馨提示：翻页前，请先提交当前页的数据</div>
      <div v-if="radio != '4'">
        <el-button class="btns" type="primary" @click="handleSettlement" :disabled="multipleSelection.length == 0">确定</el-button>
      </div>
      <el-button class="btns" type="primary" @click="onTotalTechFeeRateSet" v-if="radio=='4'">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      submitLoding: false,

      supplierList: [],
      targetKeys: [],

      checked: false,
      radios: [
        { label: '1', name: '按商品设置' },
        { label: '2', name: '按品牌设置' },
        { label: '3', name: '按品类设置' },
        { label: '4', name: '全部设置' },
      ],
      radios1: [
        { label: '1', name: '按商品设置' },
        { label: '2', name: '按品牌设置' },
        { label: '3', name: '按品类设置' },
      ],
      options: [{
        value: '0',
        label: '是'
      }, {
        value: '1',
        label: '否'
      }],
      radio: '1',
      input: 0,
      techFeeRateStatus: "",//服务费率
      serialId: "",//商品编号
      productName: "",//商品名称
      barCode: "",  // 商品条形码
      firstCategoryName: "",  // 一级类目
      secondCategoryName: "",  // 二级类目
      brand: "",  // 品牌
      newtableLabel: [],//空数组
      tableData: [],//表格数据
      tableLabel: [
        // { prop: "serialId", label: "商品编号", radio: ['1'] },
        { prop: "productName", label: "商品名称", radio: ['1'] },
        { prop: "categoryName", label: "分类", radio: ['1'] },
        { prop: "brand", label: "品牌", radio: ['2'] },
        { prop: "barCode", label: "商品条形码", radio: ['1'] },
        { prop: "firstCategoryName", label: "一级类目", radio: ['3'] },
        { prop: "secondCategoryName", label: "二级类目", radio: ['3'] },
        // { prop: "feeRate", label: "技术服务费率（%）", radio: ['1', '2', '3', '4'] },
        // { prop: "createdTime", label: "设置时间", radio: ['1', '2', '3', '4'] },
        // { prop: "validityPeriod", label: "操作", radio: ['2', '3'] },
      ],//标头
      multipleSelection: [], // 选中数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [5, 10, 20, 30], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      disabled: true,
      type: this.$route.query.type
    };
  },
  created() {
    this.handleClick()
    this.generateData()
    // console.log(this.type);

  },
  methods: {
    // 穿梭框
    generateData() {
      const dataList = [];
      const cities = [];
      let api = ''
      if (this.type == 1) {
        api = this.$api.supermarkList //商超集合
      }
      if (this.type == 2) {
        api = this.$api.supplierList //供应商集合
      }
      if (this.type == 1 || this.type == 2) {
        this.$axios.get(api).then((res) => {
          if (res.data.code == 100) {
            res.data.result.forEach(item => {
              dataList.push({
                label: this.type == 2 ? item.supplierName : item.shopName,
                key: this.type == 2 ? item.supplierId : item.shopId,
                // pinyin: pinyin[index]
              });
            });
            // console.log(dataList);
          }
        })
        // const pinyin = ['shanghai', 'beijing', 'guangzhou', 'shenzhen', 'nanjing', 'xian', 'chengdu'];
        cities.forEach((city, index) => {
          dataList.push({
            label: city,
            key: index,
            // pinyin: pinyin[index]
          });
        });
        this.supplierList = dataList
      }
    },
    ontransfer() {


    },
    filterMethod(query, item) {

      return item.label.indexOf(query) > -1;
    },
    handleClick() {
      this.newtableLabel = this.tableLabel.filter(item => item.radio.includes(this.radio))
      if (this.radio == '1') {
        this.productTechFeeRateSetPageList()
      } if (this.radio == '2') {
        this.brandTechFeeRateSetPageList()
      } if (this.radio == '3') {
        this.categoryTechFeeRateSetPageList()
      }
    },
    handleInput2() {
      console.log(this.input);
    },
    onblur(row) {
      // console.log(row);
      this.multipleSelection.forEach(t => {
        if (t.bizId == row.bizId) {
          t.bizId = row.bizId
          t.bizType = row.bizType
          t.feeRate = row.feeRate
        }
      })
    },
    // 商品list
    productTechFeeRateSetPageList() {
      this.$axios.get(this.$api.productTechFeeRateSetPageList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          barCode: this.barCode,//商品条形码
          brand: this.brand,//品牌
          categoryId: this.value,//分类id
          serialId: this.serialId,//商品编号
          productName: this.productName,//商品名称
          firstCategoryName: this.firstCategoryName,//一级类目
          secondCategoryName: this.secondCategoryName,//二级类目
          techFeeRateStatus: this.techFeeRateStatus,//是否设置费率：0是；1否
          bizUserType: this.type,

        }
      }).then((res) => {
        let data = res.data.result.list
        this.tableData = data
        // data数据里添加 disabled字段 = false,并返回完整数据
        // this.tableData = data.map(item => {
        //   item.disabled = true;
        //   return item;
        // });
        this.totalItemsCount = res.data.result.totalCount
      })
    },
    // 品牌list
    brandTechFeeRateSetPageList() {
      this.$axios.get(this.$api.brandTechFeeRateSetPageList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          barCode: this.barCode,//商品条形码
          brand: this.brand,//品牌
          categoryId: this.value,//分类id
          serialId: this.serialId,//商品编号
          productName: this.productName,//商品名称
          firstCategoryName: this.firstCategoryName,//一级类目
          secondCategoryName: this.secondCategoryName,//二级类目
          techFeeRateStatus: this.techFeeRateStatus,//是否设置费率：0是；1否
          bizUserType: this.type,


        }
      }).then((res) => {
        let data = res.data.result.list
        this.tableData = data
        // data数据里添加 disabled字段 = false,并返回完整数据
        // this.tableData = data.map(item => {
        //   item.disabled = true;
        //   return item;
        // });
        this.totalItemsCount = res.data.result.totalCount
      })
    },
    // 分类list
    categoryTechFeeRateSetPageList() {
      this.$axios.get(this.$api.categoryTechFeeRateSetPageList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          barCode: this.barCode,//商品条形码
          brand: this.brand,//品牌
          categoryId: this.value,//分类id
          serialId: this.serialId,//商品编号
          productName: this.productName,//商品名称
          firstCategoryName: this.firstCategoryName,//一级类目
          secondCategoryName: this.secondCategoryName,//二级类目
          techFeeRateStatus: this.techFeeRateStatus,//是否设置费率：0是；1否
          bizUserType: this.type,


        }
      }).then((res) => {
        let data = res.data.result.list
        this.tableData = data
        // this.tableData = data.map(item => {
        //   item.disabled = true;
        //   return item;
        // });
        this.totalItemsCount = res.data.result.totalCount
      })
    },
    // 批量全选
    onCheckbox() {
      if (this.checked == true) {
        this.tableData.forEach(item => {
          item.disabled = true;
        });
        // console.log(this.tableData);
      } else {
        this.tableData.forEach(item => {
          item.disabled = false;
        });
        this.$refs.multipleTable.clearSelection()
        this.input = 0
      }
    },
    // 选中
    handleSelectionChange(val) {
      // 选中数据时disabled = false,取消勾选数据时disabled = true
      // this.tableData.forEach(item => {
      //   item.disabled = true;
      // });
      // val.forEach(item => {
      //   item.disabled = false;
      // });
      if (this.checked == true) {
        this.multipleSelection = val.map(t => ({
          bizId: t.bizId, bizType: t.bizType
        }))
      } else {
        this.multipleSelection = val.map(t => ({
          feeRate: t.feeRate, bizId: t.bizId, bizType: t.bizType
        }))
      }

    },
    // 确定
    handleSettlement() {
      this.loading = true

      // console.log(this.targetKeys);
      // console.log(this.multipleSelection);
      let params = {}
      if (this.type == 1 || this.type == 2) {
        params = {
          changeTechFeeRateDtoList: this.multipleSelection,
          bizUserIdList: this.targetKeys,
          outFeeRate: this.input,
          status: this.checked == true ? 1 : 0,
          bizUserType: this.type
        }
      } else {
        params = {
          changeTechFeeRateDtoList: this.multipleSelection,
          // bizUserIdList: this.targetKeys,
          outFeeRate: this.input,
          status: this.checked == true ? 1 : 0,
          bizUserType: this.type
        }
      }
      let api = ''
      if (this.type == 1 || this.type == 2) {
        api = this.$api.changeUserTechFeeRateList //商超集合
      }
      if (this.type == 3) {
        api = this.$api.changeTechFeeRateList //供应商集合
      }
      this.$axios.post(api, params).then((res) => {
        if (res.data.code == 100) {
          this.loading = false
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.input = 0
          this.targetKeys = []
          this.checked = false
          this.handleClick()
        }
      }).catch(() => {
        this.loading = false

      })
    },
    // 全部设置
    onTotalTechFeeRateSet() {
      // console.log(this.multipleSelection);
      this.$axios.post(this.$api.totalTechFeeRateSet, {
        bizType: '4',
        feeRate: this.input
      }).then((res) => {
        if (res.data.code == 100) {
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.input = 0
          this.handleClick()
        }
      })
    },
    // handleIncrease(row) {
    //   console.log(row);
    // },
    // 筛选
    onScreenData() {
      this.currentPage = 1;
      if (this.radio == '1') {
        this.productTechFeeRateSetPageList()
      } if (this.radio == '2') {
        this.brandTechFeeRateSetPageList()
      } if (this.radio == '3') {
        this.categoryTechFeeRateSetPageList()
      }
    },
    // 换页
    handleCurrentChange() {
      this.page += 1;
      if (this.radio == '1') {
        this.productTechFeeRateSetPageList()
      } if (this.radio == '2') {
        this.brandTechFeeRateSetPageList()
      } if (this.radio == '3') {
        this.categoryTechFeeRateSetPageList()
      }
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onScreenData();
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 20px 30px;
  .back {
    padding: 0px !important;
  }
  ::v-deep .el-transfer-panel {
    width: 300px !important;
  }
  .search-box {
    font-size: 16px;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
  .search-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .input-box {
      margin-right: 20px;
    }
  }
}
</style>