// 技术服务费
export default [
  {
    path: '/technicalServiceFee',
    meta: { title: '技术服务费' },
    component: () => import('../views/technicalServiceFee/index.vue'),
    children: [
      {
        path: '/technicalServiceFee/supplierFee',
        meta: { title: '供应商服务费', isMenu: true },
        component: () => import('../views/technicalServiceFee/supplierFee.vue'),
      },
      {
        path: '/technicalServiceFee/shopFee',
        meta: { title: '商超服务费', isMenu: true },
        component: () => import('../views/technicalServiceFee/shopFee.vue'),
      },
      {
        path: '/technicalServiceFee/detail/shopFeeDetail',
        meta: { title: '商超服务费详情' },
        component: () => import('../views/technicalServiceFee/detail/shopFeeDetail.vue'),
      },
      {
        path: '/technicalServiceFee/detail/supplierFeeDetail',
        meta: { title: '供应商服务费详情' },
        component: () => import('../views/technicalServiceFee/detail/supplierFeeDetail.vue'),
      },
      {
        path: '/technicalServiceFee/serviceCharge',
        meta: { title: '服务费管理', isMenu: true },
      },
      {
        // name: "platform",
        path: "/technicalServiceFee/serviceCharge/platform",
        meta: { title: "平台服务费", isMenu: true },
        component: () => import("../views/technicalServiceFee/serviceCharge.vue"),
      },
      {
        // name: "supplier",
        path: "/technicalServiceFee/serviceCharge/supplier",
        meta: { title: "供应商服务费", isMenu: true },
        component: () => import("../views/technicalServiceFee/supplierCharge.vue"),
      },
      {
        // name: "supermarket",
        path: "/technicalServiceFee/serviceCharge/supermarket",
        meta: { title: "商超服务费", isMenu: true },
        component: () => import("../views/technicalServiceFee/supermarketCharge.vue"),
      },
      {
        // name: "serviceSetup",
        path: "/technicalServiceFee/detail/serviceSetup",
        meta: { title: "批量设置服务费" },
        component: () => import("../views/technicalServiceFee/detail/serviceSetup.vue"), 
      },
      {
        // name: "serviceInfo",
        path: "/technicalServiceFee/detail/serviceInfo",
        meta: { title: "服务费查看" },
        component: () => import("../views/technicalServiceFee/detail/serviceInfo.vue"),
      },
    ],
  },
];