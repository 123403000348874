<template>
  <div class="con_bid_list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="tendersName" placeholder="请输入招标名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="contractName" placeholder="请输入合同名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-autocomplete v-model="bidPeriod" :fetch-suggestions="getbidPeriodPagelist" placeholder="搜索标期" @select="onSearch" value-key="bidPeriod" @change="onSearch" clearable></el-autocomplete>
        <el-select v-model="isNew" placeholder="补签状态" @change="onSearch" clearable>
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-select v-model="contractStatus" placeholder="合同状态" @change="onSearch" clearable>
          <el-option label="未完成" :value="0"></el-option>
          <el-option label="已完成" :value="1"></el-option>
        </el-select>
        <el-button type="primary" @click="handleAdd">新建合同</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column show-overflow-tooltip prop="number" label="序号" width="100">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="200" show-overflow-tooltip prop="contractName" label="合同名称"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="bidPeriod" label="标期"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="tendersLabel" label="标号"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="tendersName" label="招标名称"></el-table-column>
          <el-table-column min-width="200" show-overflow-tooltip prop="" label="中标有效期">
            <template slot-scope="scope">{{ scope.row.startTime }}至{{ scope.row.endTime }}</template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="" label="合同状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.contractStatus == 0" style="color: red">未完成</span>
              <span v-if="scope.row.contractStatus == 1">已完成</span>
            </template>
          </el-table-column> -->
          <el-table-column show-overflow-tooltip prop="" label="签署状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.signStatus == 0" style="color: red">未完成</span>
              <span v-if="scope.row.signStatus == 1">已完成</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="是否需要补签" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.isNew == 0 ? "否" : scope.row.isNew == 1 ? "是" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="100" align="center">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="" plain @click="handleView(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      submitLoding: false,
      isAdd: true,
      tendersName: "", //标书名称
      contractName: "", //合同名称
      contractStatus: "", //合同状态：0未完成，1已完成
      isNew: "", //是否有补签：0否，1是
      bidPeriod: "", //标期
      tableData: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("contractBidListCatch"))
    this.tendersName = obj?.tendersName
    this.contractName = obj?.contractName
    this.bidPeriod = obj?.bidPeriod
    this.isNew = obj?.isNew
    this.contractStatus = obj?.contractStatus
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("contractBidListCatch", {})

    this.getList()
  },
  methods: {
    // 列表
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.contractTendersList, {
          params: {
            tendersName: this.tendersName, // 标书名称
            contractName: this.contractName, // 合同名称
            bidPeriod: this.bidPeriod, // 标期
            contractStatus: this.contractStatus, // 合同状态：0未完成，1已完成
            isNew: this.isNew, // 是否有补签：0否，1是
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    //标期下拉列表
    getbidPeriodPagelist(queryString, cb) {
      this.bidPeriodloading = true
      this.$axios
        .get(this.$api.bidPeriodPage, {
          params: {
            page: 1,
            pageSize: 50,
            bidPeriod: queryString,
          },
        })
        .then(res => {
          this.bidPeriodloading = false
          this.bidPeriodlist = res.data.result.list
          cb(this.bidPeriodlist)
        })
    },
    // 新建
    handleAdd() {
      this.$router.push({
        path: "/contract/detail/add",
      })
    },
    // 查看
    handleView(row) {
      let obj = {
        tendersName: this.tendersName,
        contractName: this.contractName,
        bidPeriod: this.bidPeriod,
        isNew: this.isNew,
        contractStatus: this.contractStatus,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("contractBidListCatch", obj)
      this.$router.push({ path: "/contract/detail/contractList", query: { id: row.tendersId } })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.con_bid_list {
  .search {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;

    .el-select,
    .el-autocomplete,
    .el-input {
      width: 160px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-autocomplete,
    .el-select,
    .el-input,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .red {
    color: red;
  }

  .grey {
    color: #03100e99;
  }
}
</style>
