<template>
  <!-- 线上订单 -->
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <div class="right">
          <!-- 输入搜索 -->
          <div class="search">
            <el-input style="margin-right:10px;" placeholder="请输入店铺名称" suffix-icon="el-icon-search" v-model="shopName"
              :clearable="true" @change="onCurrentPage"></el-input>
          </div>
          <div class="search">
            <el-input style="margin-right:10px;" placeholder="请输入用户编号" suffix-icon="el-icon-search" v-model="userCode"
              :clearable="true" @change="onCurrentPage"></el-input>
          </div>
          <div class="search">
            <el-input style="margin-right:10px;" placeholder="请输入订单编号" suffix-icon="el-icon-search" v-model="orderCode"
              :clearable="true" @change="onCurrentPage"></el-input>
          </div>
          <!-- 下拉搜索 -->
          <div class="select">
            <!-- 选择状态 -->
            <el-select v-model="deliveryStatus" placeholder="请选择订单子状态" clearable @change="onCurrentPage">
              <el-option v-for="item in orderFormStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- 下单时间 -->
            <el-date-picker v-model="formInline.time" @change="gettime" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期"
              style="border-radius: 10px; background: #f2f2f2; margin-left: 20px; border: none;"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <!-- 导出 -->

          </div>
        </div>
      </header>
      <!-- tab栏 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部订单" name="allOrder"></el-tab-pane>
        <el-tab-pane label="待付款" name="due"></el-tab-pane>
        <el-tab-pane label="处理中" name="inProcess"></el-tab-pane>
        <el-tab-pane label="已完成" name="completed"></el-tab-pane>
        <el-tab-pane label="已取消" name="cancelled"></el-tab-pane>
      </el-tabs>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="userCode" label="用户编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="orderCode" label="订单编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="shopCode" label="店铺编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="shopName" label="店铺名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="orderType" label="订单类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ getOrderTypeText(scope.row.orderType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="orderStatus" label="订单状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div :style="{ color: getStatusColor(scope.row.orderStatus) }">
                {{ getStatusText(scope.row.orderStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="deliveryStatus" label="订单子状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div :style="{ color: getOrderFormStatusColor(scope.row.deliveryStatus) }">
                {{ getOrderFormStatusText(scope.row.deliveryStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="payStatus" label="支付状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div :style="{ color: paymentStatusColor(scope.row.payStatus) }">
                {{ scope.row.payStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="payAmount" label="订单金额(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="下单时间" width="200" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" min-width="200" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <!-- 订单状态为处理中并且订单子状态为待接单显示 -->
              <div v-if="scope.row.deliveryStatus == 1">
                <!-- <el-button type="primary" size="small" plain @click="handleOrderTaking(scope.row)">接单</el-button> -->
                <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">订单详情</el-button>
                <!-- <el-button type="error" size="small" plain @click="handleCancel(scope.row)">取消订单</el-button> -->
              </div>
              <div v-else>
                <!-- <el-button type="danger" size="small" plain v-if="scope.row.deliveryStatus == 7"
                  @click="handleRefund(scope.row)">退款</el-button>
                <el-button type="danger" size="small" plain
                  v-if="scope.row.orderType == 3 && scope.row.payStatus == '已支付' && scope.row.deliveryStatus == 5"
                  @click="handleVerify(scope.row)">确认收货</el-button> -->
                <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">订单详情</el-button>
                <!-- <el-button type="error" size="small" plain
                  v-if="scope.row.deliveryStatus == 0 && scope.row.orderStatus == 0"
                  @click="handleCancel(scope.row)">取消订单</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      startTime: '',//开始时间
      endTime: '',//结束时间
      formInline: {
        time: []
      },//日期区间
      activeName: 'allOrder',
      // 订单子状态
      orderFormStatus: [
        {
          value: '0',
          label: '待付款'
        }, {
          value: '1',
          label: '待接单'
        }, {
          value: '2',
          label: '备货中'
        }, {
          value: '3',
          label: '骑手配送中'
        }, {
          value: '4',
          label: '已完成'
        }, {
          value: '5',
          label: '待自提'
        }, {
          value: '6',
          label: '售后中'
        }, {
          value: '7',
          label: '待退款'
        }, {
          value: '8',
          label: '已退款'
        }, {
          value: '9',
          label: '已取消'
        }, {
          value: '10',
          label: '待取货'
        },
      ],
      orderStatus: '',//订单状态
      deliveryStatus: '',//选择订单子状态:0.待付款,1,待接单,2备货中,3,骑手配送中,4,以完成5,待自提,6,售后中,7,待退款,8,已退款,9,已取消,10待取货
      orderCode: '',//订单编号
      userCode: '',//用户编号
      shopName: '',//店铺编号
    }
  },
  created() {
    this.userCode = this.$route.query.userCode || '';//用户编号
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("onlineOrderM"))
    this.orderCode = obj?.orderCode
    this.userCode = obj?.userCode
    this.deliveryStatus = obj?.deliveryStatus
    this.orderStatus = obj?.orderStatus
    this.shopName = obj?.shopName
    this.startTime = obj?.startTime
    this.endTime = obj?.endTime
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("onlineOrderM", {})
    // 获取列表
    this.getRequest()//请求数据
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersList, {
        params: {
          orderSource: 2,//订单来源：1线下；2线上商城(必填)
          orderCode: this.orderCode || null,//订单编号
          userCode: this.userCode || null,//用户编号
          deliveryStatus: this.deliveryStatus || null, //选择订单子状态
          orderStatus: this.orderStatus || null,//订单状态
          // shopId: this.$store.state.userInfo.shopId,//登录
          startTime: this.startTime || null,//开始时间
          endTime: this.endTime || null,//结束时间
          shopName: this.shopName || null,//店铺名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 用户编号搜索+清除路径上的参数
    // labelSearch() {
    //   this.getRequest()
    //   if (!this.userCode) {
    //     window.history.replaceState(null, null, this.gelDelURL());
    //   }
    // },
    // // 清空路径上携带的参数
    // gelDelURL() {
    //   return window.location.href
    //     .substring(0, window.location.href.indexOf("?"));
    // },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : "";
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : "";
      this.onCurrentPage()
      // console.log('开始时间:', this.startTime, '结束时间:', this.endTime);
    },
    // tab栏选择
    handleClick(tab) {
      const { name } = tab
      if (name == 'due') {
        this.orderStatus = '0';
      } else if (name == 'inProcess') {
        this.orderStatus = '1';
      } else if (name == 'completed') {
        this.orderStatus = '4';
      } else if (name == 'cancelled') {
        this.orderStatus = '-1';
      } else {
        this.orderStatus = '';
      }
      this.onCurrentPage()
    },
    // 查看详情按钮
    handleCommand(row) {
      let obj = {
        orderCode: this.orderCode,
        userCode: this.userCode,
        deliveryStatus: this.deliveryStatus,
        orderStatus: this.orderStatus,
        shopName: this.shopName,
        startTime: this.startTime,
        endTime: this.endTime,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("onlineOrderM", obj)
      if (row.orderType == 2) {
        this.$router.push({ name: 'distributionDet', query: { orderId: row.orderId } })//配送详情
      } else {
        this.$router.push({ name: 'selfTakeDet', query: { orderId: row.orderId } })//自取详情
      }
    },
    // 确认收货按钮
    handleVerify(row) {
      // console.log('确认收货', row.orderId);
      const { orderId } = row
      this.$axios.post(this.$api.ordersPickup,
        orderId
      ).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({ message: '恭喜你，确认收货成功!', type: 'success' });
          this.onCurrentPage()
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 接单按钮
    handleOrderTaking(row) {
      console.log('接单', row.orderId);
      const { orderId } = row
      // this.$message({ message: '恭喜你，接单成功!', type: 'success' });
      this.$axios.post(this.$api.takingOrders,
        orderId
      ).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({ message: '恭喜你，接单成功!', type: 'success' });
          this.onCurrentPage()
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 取消按钮
    handleCancel(row) {
      console.log('取消', row);
      // this.$message({ message: '恭喜你，取消订单成功!', type: 'success' });
      const { orderId } = row
      this.$axios.post(this.$api.cancelOrders,
        orderId
      ).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({ message: '恭喜你，取消订单成功!', type: 'success' });
          this.onCurrentPage()
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 退款按钮
    handleRefund(row) {
      console.log('退款', row);
      const { orderId } = row
      // this.$message({ message: '恭喜你，退款成功!', type: 'success' });
      this.$axios.post(this.$api.refundOrders,
        orderId
      ).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({ message: '恭喜你，处理退款成功!', type: 'success' });
          this.onCurrentPage()
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },
    // 订单状态文字颜色
    getStatusColor(status) {
      switch (String(status)) {
        case '0':
          return 'red';//待付款
        default:
          return '';
      }
    },
    // 订单状态
    getStatusText(status) {
      switch (String(status)) {
        case '0':
          return '待付款';
        case '1':
          return '处理中';
        case '4':
          return '已完成';
        default:
          return '已取消';
      }
    },
    // 订单子状态文字颜色
    getOrderFormStatusColor(status) {
      switch (String(status)) {
        case '0':
          return 'red';//待付款
        case '1':
          return 'red';//待接单
        case '7':
          return 'red';//待退款
        default:
          return '';
      }
    },
    // 订单子状态
    getOrderFormStatusText(status) {
      switch (String(status)) {
        case '0':
          return '待付款';
        case '1':
          return '待接单';
        case '2':
          return '备货中';
        case '3':
          return '骑手配送中';
        case '4':
          return '已完成';
        case '5':
          return '待自提';
        case '6':
          return '售后中';
        case '7':
          return '待退款';
        case '8':
          return '已退款';
        case '9':
          return '已取消';
        default:
          return '待取货';
      }
    },
    // 支付状态文字颜色
    paymentStatusColor(status) {
      switch (String(status)) {
        case '待支付':
          return 'red';//待支付
        default:
          return '';
      }
    },
    // 订单类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '2':
          return '配送';
        case '3':
          return '自提';
        default:
          return '-';
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;

      .right {
        display: flex;
        margin-bottom: 20px;

        .search {
          margin-right: 10px;
        }

        .header-btn {
          margin-left: 10px;
        }

        .select {
          display: flex;
          justify-content: space-between;

          .el-select {
            margin-right: 10px;
            width: 200px;
          }

        }

      }
    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

  .el-date-editor,
  .el-range-editor,
  .el-input__inner,
  .el-date-editor--daterange {
    margin-left: 0px !important;
  }

  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 250px;

  }

}
</style>
