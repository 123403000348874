<template>
  <div class="supplierManagement" v-loading="loading">
    <div class='globle_border content'>
      <header>
        <el-input placeholder="请输入店铺名称" suffix-icon="el-icon-search" v-model="shopName" clearable
          @change="onCurrentPage"></el-input>
        <el-input placeholder="请输入用户编号" suffix-icon="el-icon-search" v-model="userCode" clearable
          @change="onCurrentPage"></el-input>
        <el-input placeholder="请输入订单编号" suffix-icon="el-icon-search" v-model="orderCode" clearable
          @change="onCurrentPage"></el-input>
        <el-input placeholder="请输入售后单号" suffix-icon="el-icon-search" v-model="refundCode" clearable
          @change="onCurrentPage"></el-input>
        <!-- 选择状态 -->
        <el-select v-model="orderType" placeholder="请选择订单类型" clearable @change="onCurrentPage">
          <el-option v-for="item in orderTypeArr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="refundType" placeholder="请选择售后类型" clearable @change="onCurrentPage">
          <el-option v-for="item in afterSaleType" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="status" placeholder="请选择商家处理状态" clearable @change="onCurrentPage">
          <el-option v-for="item in orderStatus" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="platformStatus" placeholder="请选择平台处理状态" clearable @change="onCurrentPage">
          <el-option v-for="item in platformStatusVal" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="userCode" label="用户编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="180" prop="orderCode" label="订单编号" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="orderType" label="订单类型" show-overflow-tooltip min-width="100" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.orderType == 3">自提</div>
              <div v-if="scope.row.orderType == 2">配送</div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="shopName" label="店铺名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="refundCode" label="售后单号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="refundType" label="售后类型" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.refundType == 1">退货退款</div>
              <div v-if="scope.row.refundType == 2" style="color: red;">仅退款</div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="productAmount" label="商品金额(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="packAmount" label="打包费(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="deliveryAmount" label="配送费(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="refundAmount" label="退款金额(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column min-width="130" prop="responsibilityType" label="责任分类" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.responsibilityType == 1">商家责任</div>
              <div v-if="scope.row.responsibilityType == 2">骑手责任</div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="orderTime" label="下单时间" width="200" align="center">
          </el-table-column>
          <el-table-column min-width="130" prop="returnTime" label="售后申请时间" width="200" align="center">
          </el-table-column>
          <el-table-column min-width="130" prop="" label="商家处理状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div :style="{ color: getOrderStatusColor(scope.row.status) }">
                {{ getOrderStatusText(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" prop="" label="平台处理状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.platformStatus == null">-</div>
              <div v-if="scope.row.responsibilityType == null && scope.row.platformStatus == -1">-</div>
              <div v-if="scope.row.responsibilityType == 1">-</div>
              <div v-if="scope.row.responsibilityType == 2"
                :style="{ color: getOrderPlatformStatusColor(scope.row.platformStatus) }">
                {{ getOrderPlatformStatusText(scope.row.platformStatus) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="380" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" v-if="scope.row.platformStatus == 2" size="small"
                @click="handleView(scope.row)">查看原因</el-button>
              <!-- <el-button v-if="scope.row.status == 0 && scope.row.responsibilityType !== null" type="danger"
                size="small" plain @click="handleRefund(scope.row)">退款</el-button> -->
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">订单详情</el-button>
              <el-button type="primary" size="small" @click="handleHandle(scope.row)"
                v-if="scope.row.platformStatus == 0">售后处理</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div>
    <!-- 查看不通过原因 -->
    <el-dialog title="查看原因" :visible.sync="centerDialogVisible" width="50%" destroy-on-close>
      <h3 style="margin-bottom: 20px;">不通过原因:</h3>
      <el-input type="textarea" resize="none" :rows="10" v-model="platformRefuseMsg" readonly></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗 -->
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog" :ruleForm="ruleForm">
    </child>
  </div>
</template>

<script>
import child from './detail/dialog.vue'
export default {
  components: {
    child,
  },
  data() {
    return {
      loading: false,
      showFlag: false,//售后弹窗显隐
      // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 订单类型
      orderTypeArr: [
        {
          value: '2',
          label: '配送'
        },
        {
          value: '3',
          label: '自提'
        },
      ],
      // 商家处理状态
      orderStatus: [
        {
          value: '-1',
          label: '已撤销'
        },
        {
          value: '0',
          label: '待处理'
        }, {
          value: '1',
          label: '已退款'
        }, {
          value: '2',
          label: '拒绝退款'
        }, {
          value: '3',
          label: '待平台处理'
        },
      ],
      // 平台处理状态
      platformStatusVal: [
        {
          value: '-1',
          label: '-'
        },
        {
          value: '0',
          label: '待处理'
        }, {
          value: '1',
          label: '审核成功'
        }, {
          value: '2',
          label: '审核不通过'
        },
      ],
      // 售后类型
      afterSaleType: [
        {
          value: '1',
          label: '退货退款'
        }, {
          value: '2',
          label: '仅退款'
        },
      ],
      orderType: '',//订单类型
      status: '',//商家处理状态
      platformStatus: '',//平台处理状态
      refundType: '',//售后类型
      userCode: '',//用户编号
      orderCode: '',//订单编号
      refundCode: '',//售后单号
      ruleForm: {},
      shopName: '',//店铺编号
      centerDialogVisible: false,//不通过原因弹窗
      platformRefuseMsg: '',//审核失败原因
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.orderCode = obj?.orderCode
    this.userCode = obj?.userCode
    this.refundCode = obj?.refundCode
    this.orderType = obj?.orderType
    this.status = obj?.status
    this.platformStatus = obj?.platformStatus
    this.refundType = obj?.refundType
    this.shopName = obj?.shopName
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("afterSaleOrder", {})
    // 获取列表
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("afterSaleOrder", {})
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersRefundList, {
        params: {
          orderCode: this.orderCode || null,//订单编号
          refundCode: this.refundCode || null,//售后编号
          userCode: this.userCode || null,//用户编号
          orderType: this.orderType || null,//订单类型
          status: this.status || null,//商家处理状态：-1撤销；0处理中；1成功；2售后拒绝
          platformStatus: this.platformStatus || null,//平台审核:-1无需审核,0.待处理,1.成功,2.失败
          refundType: this.refundType || null,//售后类型
          shopName: this.shopName || null,//店铺名称
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          this.tableData = result.list
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },

    // 操作按钮
    handleCommand(row) {
      let obj = {
        orderCode: this.orderCode,
        userCode: this.userCode,
        refundCode: this.refundCode,
        status: this.status,
        shopName: this.shopName,
        platformStatus: this.platformStatus,
        refundType: this.refundType,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("afterSaleOrder", obj)
      this.$router.push({ name: 'afterSaleOrderDet', query: { orderRefundId: row.orderRefundId } })
    },
    // 退款按钮
    handleRefund() {
      // const { orderId } = row
      const that = this
      this.$confirm("此操作将进行退款操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          that.$message({ message: "恭喜你，处理退款成功!暂无退款接口", type: "success" })
          // that.$axios
          //   .post(that.$api.refundOffOrders, { orderId })
          //   .then(res => {
          //     const { code } = res.data
          //     if (code == 100) {
          //       that.$message({ message: "恭喜你，处理退款成功!", type: "success" })
          //       that.onCurrentPage()
          //     }
          //   })
          //   .catch(err => {
          //     console.log(err)
          //   })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退款",
          })
        })
    },
    // // 弹窗显示
    // handleOneHandle(row) {
    //   this.showFlag = true
    //   this.ruleForm = row
    //   //console.log('售后处理', row.orderRefundId);
    // },
    // 弹窗显示
    handleHandle(row) {
      const { orderRefundId } = row
      this.showFlag = true
      // console.log('第二次售后处理', row.platformStatus);
      this.$axios.get(this.$api.ordersRefundTowUpdate, {
        params: {
          orderRefundId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.ruleForm = result
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 不通过原因弹窗显示
    handleView(row) {
      this.centerDialogVisible = true
      this.platformRefuseMsg = row.platformRefuseMsg
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
      this.onCurrentPage()
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()//请求数据
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getRequest()
    },
    // 订单状态文字颜色
    getOrderStatusColor(status) {
      switch (String(status)) {
        case '-1':
          return '';//待处理
        case '0':
          return 'red';//待处理
        case '1':
          return 'red';//已退款
        default:
          return '';//拒绝退款
      }
    },
    // 订单状态 状态：-1撤销；0处理中；1成功；2售后拒绝,3等待平台处理
    getOrderStatusText(status) {
      switch (String(status)) {
        case '-1':
          return '已撤销';
        case '0':
          return '待处理';
        case '1':
          return '已退款';
        case '2':
          return '拒绝退款';
        case '3':
          return '待平台处理';
        default:
          return;
      }
    },
    // 订单状态文字颜色
    getOrderPlatformStatusColor(status) {
      switch (String(status)) {
        case '2':
          return 'red';//审核不通过
        case '0':
          return 'red';//待处理
        case '1':
          return '';//已退款
        default:
          return '';//拒绝退款
      }
    },
    // 平台审核:-1无需审核,0.待处理,1.成功,2.失败 
    getOrderPlatformStatusText(status) {
      switch (String(status)) {
        case '-1':
          return '-';
        case '0':
          return '待处理';
        case '1':
          return '审核成功';
        case '2':
          return '审核不通过';
        default:
          return;
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;

    }

    // 头部+搜索
    header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        margin-left: 10px;
        width: 180px;
        margin-bottom: 20px;
      }

      .el-button {
        margin-left: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .tableBox {
    overflow: auto;


    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: '';
      position: absolute;
      background-color: #EBEEF5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }

  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;

  }

}
</style>
