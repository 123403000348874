<!-- 应标审核 -->

<template>
  <div class="applicationExamine">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="auditValue" placeholder="全部状态" @change="onSearch" clearable>
          <el-option v-for="item in auditOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="qualificationVale" placeholder="应标资格" @change="onSearch" clearable>
          <el-option v-for="item in qualificatioOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="nameValue" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" min-width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="levelName" label="应标级别名称" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="supplierName" label="供应商名称" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="eligibleType" label="资格类型">
            <template slot-scope="scope">
              {{ scope.row.eligibleType == '1' ? '平台资格' : '独立资格' }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="deliveryArea" label="配送区域"></el-table-column>
          <el-table-column show-overflow-tooltip prop="cooperateCollege" label="合作院校"></el-table-column>
          <el-table-column show-overflow-tooltip prop="truckNumber" label="货车数量"></el-table-column>
          <el-table-column show-overflow-tooltip prop="eligiblePrice" label="金额"></el-table-column>
          <el-table-column show-overflow-tooltip prop="status" label="审核状态">
            <template slot-scope="scope">
              {{ scope.row.status == '0' ? '待审核' : (scope.row.status == '1' ? '审核通过' : '审核不通过') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="danger" plain @click="handleCheck(scope.row)" v-if="scope.row.status == '0'">审核</el-button>
              <el-button type="primary" plain @click="handleCheck(scope.row)" v-if="scope.row.status != '0'">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 下拉框(全部状态)
      auditValue: '',
      auditOptions: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核不通过",
        },
      ],
      // 下拉框(应标资格)
      qualificationVale: "",
      qualificatioOptions: [
        {
          value: "1",
          label: "平台资格",
        },
        {
          value: "2",
          label: "独立标资格",
        },
      ],
      nameValue: "", // 搜索供应商名称
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("applicationExamine"))
    this.currentPage = obj?.currentPage
    this.auditValue = obj?.auditValue
    this.qualificationVale = obj?.qualificationVale
    this.nameValue = obj?.nameValue
    
    this.$saveOrUpdateCache("applicationExamine", {})
    if (localStorage.getItem("param")) {
      const storedString = localStorage.getItem("param");
      const { auditValue, nameValue } = JSON.parse(storedString);
      this.auditValue = auditValue
      this.nameValue = nameValue
    }
    this.getPortalBiddingEligibleList();
  },

  methods: {
    // 获取列表
    getPortalBiddingEligibleList() {
      this.loading = true;
      this.$axios
        .get(this.$api.getPortalBiddingEligibleList, {
          params: {
            auditStatus: this.auditValue, // 全部状态
            eligibleType: this.qualificationVale || null, // 应标资格
            supplierName: this.nameValue,// 供应商名称
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索
    onSearch() {
      if (this.auditValue == '' || this.nameValue == '') {
        localStorage.removeItem("param");
        this.auditValue == ''
        this.nameValue == ''
      }
      this.currentPage = 1;
      this.tableData = []
      this.getPortalBiddingEligibleList();
    },

    // 查看
    handleCheck(row) {
      let obj = {
        currentPage: this.currentPage,
        auditValue: this.auditValue,
        qualificationVale: this.qualificationVale,
        nameValue: this.nameValue,
       
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("applicationExamine", obj)
      this.$router.push({
        path: "detail/applicationDetails",
        query: { id: row.id },
      });
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.currentPage = 1;
      this.pageSize = e;
      this.getPortalBiddingEligibleList();
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e;
      this.getPortalBiddingEligibleList();
    },
    // 颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 7 && row.status == "0") {
        return { color: "#E35B5A" };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.applicationExamine {
  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }
  }
}
</style>
