<!-- 按品类设置 -->

<template>
  <div class="main">

    <!-- 管理默认设置弹窗 -->
    <el-dialog title="管理默认设置" :visible.sync="dialogDefaultVisible" :before-close="handleClose">
      <el-form :model="ruleDefaultForm" ref="ruleForm" :rules="rulesDefault" class="demo-ruleForm">
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第一次预警：保质期剩余" prop="first">
              <el-input v-model="ruleDefaultForm.first" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第二次预警：保质期剩余" prop="second">
              <el-input v-model="ruleDefaultForm.second" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="handleClose">关 闭</el-button>
        <el-button type="primary" @click="submitDefaultForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    dialogDefaultVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 管理默认设置
      ruleDefaultForm: {
        first: '',
        second: '',
      },
      rulesDefault: {
        first: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        }
        ],
        second: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            const firstWarnDayValue = this.ruleDefaultForm.first;
            if (firstWarnDayValue && value && parseInt(value) > parseInt(firstWarnDayValue)) {
              callback(new Error('第二次预警天数不能大于第一次预警天数'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
        ]
      }
    }
  },
  created() {

  },
  methods: {
    // 获取默认设置
    defaultSettingSelectById() {
      this.$axios.get(this.$api.getWarningSettingsSelectById, {
        params: {
          id: 1
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.ruleDefaultForm.first = res.data.result.firstWarnDay;
          this.ruleDefaultForm.second = res.data.result.secondWarnDay;
        }
      })
    },
    // 关闭默认设置弹窗
    handleClose() {
      this.$emit('handleCloseDefaultDialog')
      this.resetDefaultForm();
    },
    // 默认弹窗设置重置表单
    resetDefaultForm() {
      this.ruleDefaultForm.first = '';
      this.ruleDefaultForm.second = '';
      this.$refs.ruleForm.resetFields();
    },
    // 默认设置弹窗表单校验
    submitDefaultForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.warningSettings, {
            bizId: 0,
            bizType: 0,   // 类型：1商品；2品牌；3分类
            bizUserId: 0, // 业务id 总后台为0
            bizUserType: 3,  // 1商超；2供应商；3总后台
            id: 1,
            firstWarnDay: Number(this.ruleDefaultForm.first), // 第一天
            secondWarnDay: Number(this.ruleDefaultForm.second), // 第二天
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleClose();
              this.$emit('settingsUpdated');
            }
          })
        }
      });
    },
  }

}
</script>

<style lang="scss" scoped>
.main {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  .row-bottom {

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 255px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    ::v-deep .el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    ::v-deep .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}
</style>
