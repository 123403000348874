<!-- 商超明细 -->
<template>
  <div class="shopDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="orderValue" placeholder="搜索申请单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="supermarketValue" placeholder="请搜索商超名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-select v-model="typeValue" placeholder="选择状态" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyNum" label="申请单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyUserName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopCode" label="商超编号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountType" label="账户类型">
            <template slot-scope="scope">
              <span>{{ scope.row.accountType == 1 ? "支付宝" : "银行卡" }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountName" label="真实姓名"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountNum" label="账号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="availableAmount" label="可提现金额"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyAmount" label="提现金额"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="申请时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="type" label="状态">
            <template slot-scope="scope">
              <span :class="scope.row.status == 1 ? 'grey' : 'red'">{{ scope.row.status == 0 ? "待审核" : scope.row.status
                == 1 ? "已转账" : scope.row.status == 2 ? "审核失败" : "转账失败" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="handleApprove(scope.row.id, 1)"
                v-if="scope.row.status == 0">通过</el-button>
              <el-button type="danger" plain @click="handleApprove(scope.row.id, 2)"
                v-if="scope.row.status == 0">不通过</el-button>
              <el-button type="primary" plain @click="handleView(scope.row.id)"
                v-if="scope.row.status != 0">查看</el-button>
              <!-- <el-button type="primary" plain @click="handleView(scope.row.id)">查看</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left"
        label-width="130px">
        <el-form-item label="不通过原因" prop="loseContent">
          <el-input type="textarea" v-model="examineForm.loseContent"
            :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitLoding: false,
      dialogVisible: false,
      rules: {
        loseContent: [{ required: true, message: "请输入不通过原因", trigger: "submit" }],
      },
      examineForm: {
        loseContent: "",
      },
      loading: false,
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "已转账",
        },
        {
          value: "2",
          label: "转账失败",
        },
        {
          value: "4",
          label: "审核失败",
        },
      ],
      typeValue: "", // 选择类型
      dataValue: "", // 时间区间
      orderValue: "", // 搜索订单号
      supermarketValue: "", // 搜索商超名称
      // 表格数据
      tableData: [
        // {}
      ],
      id: "",
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("supermarketsWithdrawal"))
    this.typeValue = obj?.typeValue
    this.orderValue = obj?.orderValue
    this.supermarketValue = obj?.supermarketValue
    this.dataValue = obj?.dataValue
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("supermarketsWithdrawal", {})
    this.getSettlementInfoList()
  },
  methods: {
    // 审核通过/不通过
    handleApprove(id, val) {
      this.id = id
      if (val == 1) {
        this.$confirm(`是否确认审核通过?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.$axios
              .put(this.$api.supermarketsAuditing, {
                id: this.id,
                status: "1",
              })
              .then(res => {
                if (res.data.code == 100) {
                  this.$message.success(res.data.desc)
                }
                this.getSettlementInfoList()
                // }).catch((err) => {
                //   this.$message.error(err.data.desc);
              })
          })
          .catch(() => { })
      } else {
        this.dialogVisible = true
      }
    },
    onCancel() {
      this.dialogVisible = false
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .put(this.$api.supermarketsAuditing, {
              id: this.id,
              auditMsg: this.examineForm.loseContent,
              status: "2",
            })
            .then(res => {
              this.$message.success(res.data.desc)
              this.getSettlementInfoList()
              this.submitLoding = false
              this.dialogVisible = false
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 获取列表
    getSettlementInfoList() {
      this.loading = true
      let params = {
        status: this.typeValue, // 选择类型
        shopName: this.supermarketValue, // 搜索商超名称
        applyNum: this.orderValue, // 搜索单号
        page: this.currentPage,
        pageSize: this.pageSize,
      }
      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0]
        params.endTime = this.dataValue[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      this.$axios
        .get(this.$api.getPageList, { params })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //路由跳转
    handleView(id) {
      let obj = {
        currentPage: this.currentPage,
        typeValue: this.typeValue,
        orderValue: this.orderValue,
        supermarketValue: this.supermarketValue,
        dataValue: this.dataValue,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("supermarketsWithdrawal", obj)
      this.$router.push({
        path: "/settlement/detail/supermarketsDetail",
        query: {
          id: id,
        },
      })
    },

    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getSettlementInfoList()
    },

    // 选择类型
    handleSelectType(value) {
      this.clearData()
      this.typeValue = value
      this.getSettlementInfoList()
    },
    // 时间搜索
    handleSelectData(value) {
      this.clearData()
      this.dataValue = value
      this.getSettlementInfoList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData()
      this.pageSize = e
      this.getSettlementInfoList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSettlementInfoList()
    },
  },
}
</script>

<style scoped lang="scss">
.shopDetail {
  .grey {
    color: grey;
  }

  .red {
    color: red;
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;
    }
  }
}
</style>
