import { render, staticRenderFns } from "./supplierAlert.vue?vue&type=template&id=839c66ce&scoped=true"
import script from "./supplierAlert.vue?vue&type=script&lang=js"
export * from "./supplierAlert.vue?vue&type=script&lang=js"
import style0 from "./supplierAlert.vue?vue&type=style&index=0&id=839c66ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "839c66ce",
  null
  
)

export default component.exports