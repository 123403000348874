<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">

      <div class="search">
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input style="margin-right: 20px" v-model="brand" placeholder="搜索品牌" suffix-icon="el-icon-search"
          @change="onSearch" clearable></el-input>
        <el-cascader class="cascader" v-model="ValueProduct" style="margin-right: 20px" :options="optionProduct"
          :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false, multiple: true }"
          collapse-tags @change="onSearch" clearable placeholder="请选择分类(可多选)"></el-cascader>
        <el-select v-model="tendersType" placeholder="选择状态" @change="onSearch" clearable style="margin-right: 20px">
          <el-option v-for="item in departmentList" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="eligibleType" placeholder="应标资格" @change="onSearch" clearable>
          <el-option v-for="item in eligibleTypes" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>

        <el-input v-model="tendersLabel" placeholder="搜索标号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <!-- <el-input v-model="period" placeholder="请选择/搜索标期" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->

        <!-- <el-select v-model="period" placeholder="请选择/搜索标期" @change="onSearch" clearable style="margin-left: 20px;" filterable remote :remote-method="getTenders_info_newBid_periods">
          <el-option v-for="item in listOfBidPeriods" :key="item" :label="item" :value="item">
          </el-option>
        </el-select> -->
        <!-- <el-input v-model="brand" placeholder="搜索品名/品牌" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->
      </div>
      <div style="display: flex;justify-content: flex-end; margin-bottom: 20px;">
        <el-button type="primary" @click="oneClickEvaluation">一键评标</el-button>
        <el-button type="primary" @click="oneClickDelet">批量作废</el-button>
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%" height="560">
          <el-table-column label="序号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="类目" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.specs }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.enterBox }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.brand }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="manufacturer" label="厂家" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.manufacturer }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="140"></el-table-column>
          <el-table-column prop="bidPeriod" label="标期"></el-table-column>
          <el-table-column prop="tendersNumber" label="标号" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="tendersTime" label="发布时间"></el-table-column> -->
          <el-table-column prop="tendersTime" label="开标时间" min-width="120"></el-table-column>
          <el-table-column prop="eligibleType" label="应标资格">
            <template slot-scope="scope">
              <span v-if="scope.row.eligibleType == 1">平台资格</span>
              <span v-if="scope.row.eligibleType == 2">独标资格</span>
            </template>
          </el-table-column>
          <el-table-column prop="tendersTime" label="招标发布时间" min-width="120"></el-table-column>
          <el-table-column label="状态" width="90">
            <template slot-scope="scope">
              <span :class="scope.row.status == 2 ? 'gray' : 'red'">
                {{ scope.row.status == 2 ? "已评标" : "待评标" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" @click="handleView(scope.row)" size="small"
                v-if="scope.row.status == 1">评标</el-button>
              <el-button class="btn" type="primary" plain @click="handleView(scope.row)" size="small"
                v-if="scope.row.status == 2">查看</el-button>
              <el-button class="btn" type="primary" @click="handleDelet(scope.row)" size="small">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <el-dialog title="一键评标" :visible.sync="dialogVisible" width="80%" :before-close="onCancelEvaluationOfBids">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <!-- <el-form-item label="选择评标规则" prop="tendersAutomaticId">
              <el-select v-model="ruleForm.tendersAutomaticId" placeholder="请选择" @change="getautomatic">
                <el-option v-for="item in tenders_automatic_list" :key="item.tendersAutomaticId" :label="item.name" :value="item.tendersAutomaticId">
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="自动类型" prop="automaticType">
              <el-input v-model="ruleForm.automaticType" placeholder="根据规则自动生成" :disabled="true"></el-input>
            </el-form-item> -->
            <el-form-item label="标号" prop="tendersNumber">
              <!-- <el-select v-model="ruleForm.tendersNumber" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select> -->
              <!-- <el-input v-model="ruleForm.tendersNumber" placeholder="请输入标号"
                @change="getTenders_automaticSelect_tender_name"></el-input> -->
              <el-autocomplete v-model="ruleForm.tendersNumber"
                :fetch-suggestions="getTenders_automaticSelect_tender_name" placeholder="请输入标号"
                :trigger-on-focus="false" @select="onTendersSelect"></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="中标数量" prop="bidWinningAmount">
              <el-input v-model="ruleForm.bidWinningAmount" placeholder="根据规则自动生成" :disabled="true"></el-input>
            </el-form-item> -->
            <el-form-item label="标书名称" prop="tendersName">
              <el-input v-model="ruleForm.tendersName" placeholder="根据标号自动生成" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelEvaluationOfBids">取 消</el-button>
        <el-button type="primary" @click="submitEvaluationOfBids('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <bidDialogcopy :show="visible" ref="addref" @onCancel="cancellist" @onSubmit="handleSubmit"></bidDialogcopy>
  </div>
</template>

<script>
import bidDialogcopy from "@/components/bidDialogcopy.vue"
export default {
  components: {
    bidDialogcopy,
  },
  // components: {}
  //   VueEditor
  // },
  data() {
    return {
      optionProduct: [],
      props: { multiple: true },
      loading: false,
      ValueProduct: "",
      submitLoding: false,
      dialogVisible: false,
      brand: "", //品牌
      productName: "", //商品名称
      tendersName: "", //标书名称
      tendersLabel: "", //标号
      period: "", //标期
      tableData: [],
      barCode: '',
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      rules: {
        tendersAutomaticId: [
          {
            required: true,
            message: "请选择规则",
            trigger: "submit",
          },
        ],
        tendersNumber: [
          {
            required: true,
            message: "请选择标号",
            trigger: "submit",
          },
        ],
        tendersName: [
          {
            required: true,
            message: "请选择标号",
            trigger: "submit",
          },
        ],
        bidWinningAmount: [
          {
            required: true,
            message: "请选择规则",
            trigger: "submit",
          },
        ],
        automaticType: [
          {
            required: true,
            message: "请选择规则",
            trigger: "submit",
          },
        ],
      },
      ruleForm: {
        tendersNumber: "", //标号
        tendersName: "", //标书名称
        // tendersAutomaticId: "", //自定评标id
        // bidWinningAmount: "", //中标数量
        // automaticType: "", //自动类型
        tendersId: "",
      },
      departmentList: [
        {
          value: "1",
          label: "待评标",
        },
        {
          value: "2",
          label: "已评标",
        },
      ],
      tendersType: "",
      eligibleType: "",
      eligibleTypes: [
        {
          value: "1",
          label: "平台资格",
        },
        {
          value: "2",
          label: "独标资格",
        },
      ],
      roleList: [],
      startStatus: "",
      options: [
        {
          value: "1",
          label: "待评标",
        },
        {
          value: "2",
          label: "已评标",
        },
      ],
      listOfBidPeriods: [],
      // status: ['', '待评标', '已完成'],
      // 一键评标
      tenders_automatic_list: [],
      select_tender_name_List: [],
      visible: false,
    }
  },
  created() {
    // this.tendersLabel = localStorage.getItem("tendersLabel")
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("bidList"))
    this.currentPage = obj?.currentPage
    this.barCode = obj?.barCode
    this.productName = obj?.productName
    this.brand = obj?.brand
    this.ValueProduct = obj?.ValueProduct
    this.tendersType = obj?.tendersType
    this.eligibleType = obj?.eligibleType
    this.tendersLabel = obj?.tendersLabel || localStorage.getItem("tendersLabel")
    this.$saveOrUpdateCache("bidList", {})
    // console.log(this.tendersNumber);
    this.getCategoryInfoList()
    this.getportalPageList()
    // this.getTenders_info_newBid_periods();
  },
  // beforeDestroy() {
  //     localStorage.removeItem("tendersLabel");
  // },
  methods: {
    cancellist() {
      // 关闭弹窗
      this.visible = false
    },
    handleSubmit(v) {
      let tenderProductId = v.map(item => {
        return item.tenderProductId
      })
      // console.log(tenderProductId);
      this.$axios
        .post(this.$api.bad_product, {
          tenderProductIdList: tenderProductId,
        })
        .then(res => {
          this.$message.success(res.data.desc)
          this.getportalPageList()
          this.cancellist()
        })
    },
    getCategoryInfoList() {
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            auditStatus: "1",
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.optionProduct = res.data.result.list
            this.optionProduct.map(item => {
              if (item.children.length == 0) {
                item.children = null
              }
            })
          }
          // this.loading = false
        })
    },
    // 一件作废
    oneClickDelet() {
      this.visible = true
    },
    // 作废
    handleDelet(row) {
      this.$confirm("是否确定作废该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.bad_product, {
              tenderProductIdList: [row.tenderProductId],
            })
            .then(res => {
              this.$message.success(res.data.desc)
              this.getportalPageList()
            })
        })
        .catch(() => { })
    },

    //根据规则获取信息
    getautomatic(v) {
      var obj = this.tenders_automatic_list.filter(e => {
        return e.tendersAutomaticId == v
      })[0]
      console.log(obj)
      this.ruleForm.automaticType = obj.automaticType == 1 ? "最低价中标" : ""
      this.ruleForm.bidWinningAmount = obj.bidWinningAmount
    },
    // 招标列表
    getportalPageList() {
      this.loading = true
      this.$axios
        .post(this.$api.portalPageList_new, {
          page: this.currentPage,
          pageSize: this.pageSize,
          eligibleType: this.eligibleType || null,
          productName: this.productName || null,
          brand: this.brand || null,
          bidPeriod: this.period || null,
          tendersLabel: this.tendersLabel || null,
          barCode: this.barCode || null,
          status: this.tendersType || null,
          categoryIdList: this.ValueProduct || [],
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 标期列表 搜索
    getTenders_info_newBid_periods(query) {
      this.$axios
        .get(this.$api.tenders_info_newBid_periods, {
          params: {
            bidPeriods: query,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.listOfBidPeriods = res.data.result.list
          }
        })
    },
    // 自动评标管理列表
    getTenders_automatic_list() {
      this.$axios
        .get(this.$api.tenders_automatic_list, {
          params: {
            page: 1,
            pageSize: 999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tenders_automatic_list = res.data.result.list
          }
        })
    },

    // 根据标号查询标书名称
    getTenders_automaticSelect_tender_name(query, cb) {
      this.$axios
        .get(this.$api.tenders_automaticSelect_tender_name, {
          params: {
            tendersNumber: query,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result
            list = list.map(t => ({
              ...t,
              value: t.tendersNumber,
            }))
            cb(list)
          }
        })
    },
    // 选择标号
    onTendersSelect(row) {
      const { tendersName, tendersId } = row
      this.ruleForm.tendersName = tendersName
      this.ruleForm.tendersId = tendersId
    },
    // 搜索
    onSearch() {
      if (this.tendersLabel == "") {
        localStorage.removeItem("tendersLabel")
      }
      this.currentPage = 1
      this.tableData = []
      this.getportalPageList()
    },

    // 一键评标（对话框）
    oneClickEvaluation() {
      this.dialogVisible = true
      this.getTenders_automatic_list()
    },
    // 一键评标（对话框）关闭
    onCancelEvaluationOfBids() {
      this.$refs["ruleForm"].resetFields()
      this.dialogVisible = false
    },
    // 一键评标表单提交
    submitEvaluationOfBids(formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return console.log("error submit!!")
        }
        this.$axios.post(this.$api.one_evaluation, this.ruleForm).then(res => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
            this.onCancelEvaluationOfBids()
            this.getportalPageList()
          }
        })
      })
    },
    //查看（跳转）
    handleView(row) {
      if (row.status == 1 && new Date().getTime() - new Date(row.tendersTime + " 00:00:00").getTime() >= 86400000) {
        this.$message.error(`开标时间为：${row.tendersTime}`)
        return
      }

      localStorage.setItem("tendersLabel", row.tendersNumber) //本地缓存标号

      let obj = {
        currentPage: this.currentPage,
        barCode: this.barCode,
        productName: this.productName,
        brand: this.brand,
        ValueProduct: this.ValueProduct,
        tendersType: this.tendersType,
        eligibleType: this.eligibleType,
        tendersLabel: this.tendersLabel,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("bidList", obj)
      this.$router.push({
        path: "/biddingManagement/detail/bidEvaluationTable",
        query: {
          id: row.tendersId,
          productName: row.productName,
          productId: row.productId,
          status: row.status,
          tendersLabel: row.tendersNumber,
        },
      })
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.tableData = []
      this.onSearch()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getportalPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .cascader {
      width: 300px;
      margin-bottom: 20px;

      ::v-deep .el-input,
      ::v-deep .el-input__inner {
        width: 300px;
      }
    }

    .el-select {
      width: 200px;
      margin-bottom: 20px;
    }

    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .el-input-number {
    width: 255px;
  }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
