/**
 * 商品管理
 */
export default {
  // 商品
  allList: '/productInfoSupplier/allList', // 多选分类商品列表 商品列表new
  productList: '/productInfoSupplier/list', // 商品列表
  productExamineList: '/productInfoSupplier/pageList', // 商品审核列表
  productListExamine: '/productInfoSupplier/audit_product', // 商品审核列表审核
  productExamineDet: '/productInfoSupplier/details', // 商品审核后详情
  producttendersList: '/productInfoSupplier/tendersList', // 招标商品列表
  producttendersList2: '/productInfoSupplier/tendersList2', // 招标商品列表2quanxian

  productAdd: 'productInfoSupplier/add', // 商品添加
  AddProductInfoSupplierList: 'productInfoSupplier/save', // 申请新商品
  updateProductPic: '/productInfoSupplier/updateProductPic', // 编辑图片
  getProductDetail: '/productInfoSupplier/productDetail', // 编辑-复现详情
  updateProduct: '/productInfoSupplier/updateProduct', // 编辑-确定
  // 编辑 重新申请
  UpdateProductInfoSupplierList: '/productInfoSupplier/update',
  // 批量导入
  importNewProductApply: '/productInfoSupplier/importNewProductApply',
  productAudit: '/productInfoSupplier/audit', // 商品审核/上下架
  batchAuditProduct: '/productInfoSupplier/batchAuditProduct', // 商品审核-批量审核
  productDelete: '/productInfoSupplier/delete', //商品删除
  productPut: '/productInfoSupplier/put', // 商品修改
  productInfoQuery: '/productInfoSupplier/query', // 商品详情
  // 分类
  categoryList: '/categoryInfo/list', // 分类列表
  categoryParentList: '/categoryInfo/parentList', // 一级分类列表（做下拉框）
  categoryAdd: '/categoryInfo/add', // 增加分类
  categoryInfoAudit: '/categoryInfo/categoryInfoAudit', // 分类审核
  categoryPut: '/categoryInfo/put', // 修改分类
  categoryInfoPutAudit: '/categoryInfo/putAudit', // 修改分类审核
  categoryQuery: '/categoryInfo/byId', // 分类详情
  categoryUploadFile: '/attachment/uploadFile', // 图片上传
  updateProductCategory: '/productInfoSupplier/updateProductCategory', //分类修改

  // categoryDelete: "/categoryInfo/delete", // 删除
  categoryDelete: '/categoryInfo/newDelete', // 删除
  // categoryAddTwoCategory: "/categoryInfo/addTwoCategory", // 增加分类两个输入框模式
  categoryAddTwoCategory: '/categoryInfo/newAdd', // 增加分类两个输入框模式
  // changeCategoryInfoPutTwo: "/categoryInfo/putTwo",   // 两个输入框修改分类
  changeCategoryInfoPutTwo: '/categoryInfo/newUpdate', // 两个输入框修改分类

  // 保质期
  deliveryProductList: '/deliveryProduct/portalList', // 供应商预警列表
  importProductInfo: '/productInfoSupplier/importProductInfo', // 导入商品
  deliveryProductDelete: '/deliveryProduct/delete', // 删除（取消预警-供应商预警）
  deliveryProductDeleteShopProductWarn: '/deliveryProduct/update_product_warn', // 删除（取消预警-商超预警）
  getShopProductWarnList: '/deliveryProduct/shop_product_warn_list', // 保质期预警 - 商超预警

    /* 
    预警设置 
  */
   // 列表
  getExpireWarningListByCategory: "/expire_warn_config/expire_warn_config_category_list", //  分类
  getExpireWarningListByBrand: "/expire_warn_config/expire_warn_config_brand_list", // 品牌 
  getExpireWarningListByProduct: "/expire_warn_config/list", // 商品
  setExpireWarning: "/expire_warn_config/set_up_expire_warn_config", // 设置(批量设置)
  warningSettings: "/expire_warn_config/warningSettings", // 设置(默认设置)
  getWarningSettingsSelectById: "/expire_warn_config/selectById", // (默认设置查询)
  // 列表
  getDeliveryProductList: '/deliveryProduct/list',
  // 取消预警（删除）
  deleteDeliveryProduct: '/deliveryProduct/delete',
  // 默认设置
  defaultSettingSelectById: '/product_warn_set/selectById',
  

  //  商品统计-列表
  getProductTotalPageList: '/productInfoSupplier/productTotalPageList',
  //  商品统计-折线图
  getProductTotalStatList: '/productInfoSupplier/productTotalStatList',

  // 商品管理>服务费管理>品牌查看
  brandDetail: '/techFeeRate/brandDetail',
  // 商品管理>服务费管理>品牌list
  brandTechFeeRatePageList: '/techFeeRate/brandTechFeeRatePageList',
  // 商品管理>服务费管理>分类list
  categoryTechFeeRatePageList: '/techFeeRate/categoryTechFeeRatePageList',
  // 商品管理>服务费管理>商品list
  productTechFeeRatePageList: '/techFeeRate/productTechFeeRatePageList',
  // 商品管理>服务费管理>全部list
  totalTechFeeRatePageList: '/techFeeRate/totalTechFeeRatePageList',
  // 商品管理>服务费管理>分类查看
  categoryDetail: '/techFeeRate/categoryDetail',
  // 商品管理>服务费管理>按品牌设置集合
  brandTechFeeRateSetPageList: '/techFeeRate/brandTechFeeRateSetPageList',
  // 商品管理>服务费管理>按分类设置集合
  categoryTechFeeRateSetPageList: '/techFeeRate/categoryTechFeeRateSetPageList',
  // 商品管理>服务费管理>按商品设置集合
  productTechFeeRateSetPageList: '/techFeeRate/productTechFeeRateSetPageList',
  categoryListPC: '/techFeeRate/categoryList',
  // 商品管理>服务费管理>编辑服务费(post)
  changeTechFeeRate: '/techFeeRate/changeTechFeeRate',
  // 商品管理>服务费管理>批量设置服务费(post)
  changeTechFeeRateList: '/techFeeRate/changeTechFeeRateList',
  // 商品管理>服务费管理>按全部设置集合(post)
  totalTechFeeRateSet: '/techFeeRate/totalTechFeeRateSet',

  // 商品包套餐管理>提交
  insertPurchasePackage: '/purchasePackage/insertPurchasePackage',
  // 商品包套餐管理列表
  selectPurchasePackage: '/purchasePackage/selectPurchasePackage',
  // 商品包套餐管理列表删除商品包
  deletePurchasePackage: '/purchasePackage/deletePurchasePackage',
  // 商品包套餐商品修改商品列表查询
  selectPurchasePackageProduct: '/purchasePackage/selectPurchasePackageProduct',
  // 商品包套餐商品修改商品列表分类查询
  selectPurchasePackageProductCategory:
    '/purchasePackage/selectPurchasePackageProductCategory',
  // 商品包套餐商品修改弹窗商品列表点击加入新商品
  insertPurchasePackageProduct: '/purchasePackage/insertPurchasePackageProduct',
  // 商品包套餐商品修改弹窗商品列表点击加入新商品后删除
  deletePurchasePackageProduct: '/purchasePackage/deletePurchasePackageProduct',
  // 商品包套餐商品修改商品包内容
  updatePurchasePackage: '/purchasePackage/updatePurchasePackage',
  // 商品包套餐商品修改商品包内容的建议箱数
  updatePurchasePackageProduct: '/purchasePackage/updatePurchasePackageProduct',
  // 商品包套餐商品修改商品包内容(已剔除)
  selectProductList: '/purchasePackage/selectProductList',
  // 商超管理->库存审核->审核
  shopAudit: '/shopInventoryApply/audit',
  // 商超管理->库存审核列表
  auditPageList: '/shopInventoryApply/auditPageList',
  // 商超管理->库存审核->批量审核
  batchAudit: '/shopInventoryApply/batchAudit',
  // 商超管理->库存审核->查看
  portalDetail: '/shopInventoryApply/portalDetail',

  // 商品管理>服务费管理>编辑服务费
  changeTechFeeRatePost: '/supplyFeeRate/changeTechFeeRateList',

  // 商品管理>服务费管理>按全部设置集合
  totalTechFeeRateSetPost: '/supplyFeeRate/totalTechFeeRateSet',

  // 商品管理>服务费管理>品牌查看
  supplyFeeRatebrandDetail: '/supplyFeeRate/brandDetail',
  // 商品管理>服务费管理>品牌list
  supplyFeeRatebrandTechFeeRatePageList:
    '/supplyFeeRate/brandTechFeeRatePageList',
  // 商品管理>服务费管理>分类list
  supplyFeeRatecategoryTechFeeRatePageList:
    '/supplyFeeRate/categoryTechFeeRatePageList',
  // 商品管理>服务费管理>商品list
  supplyFeeRateproductTechFeeRatePageList:
    '/supplyFeeRate/productTechFeeRatePageList',
  // 商品管理>服务费管理>全部list
  supplyFeeRatetotalTechFeeRatePageList:
    '/supplyFeeRate/totalTechFeeRatePageList',
  // 商品管理>服务费管理>分类查看
  supplyFeeRatecategoryDetail: '/supplyFeeRate/categoryDetail',
  // 商品管理>服务费管理>按品牌设置集合
  supplyFeeRatebrandTechFeeRateSetPageList:
    '/supplyFeeRate/brandTechFeeRateSetPageList',
  // 商品管理>服务费管理>按分类设置集合
  supplyFeeRatecategoryTechFeeRateSetPageList:
    '/supplyFeeRate/categoryTechFeeRateSetPageList',
  // 商品管理>服务费管理>按商品设置集合
  supplyFeeRateproductTechFeeRateSetPageList:
    '/supplyFeeRate/productTechFeeRateSetPageList',
  supplyFeeRatecategoryListPC: '/supplyFeeRate/categoryList',

  // 商品管理>服务费管理>商超集合
  supermarkList: '/techFeeRate/shopList',
  // 商品管理>服务费管理>供应商集合
  supplierList: '/techFeeRate/supplierList',

  // 商品管理>服务费管理>商超/供应商批量设置服务费
  changeUserTechFeeRateList: '/techFeeRate/changeUserTechFeeRateList',
};
