<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="startStatus" placeholder="全部类型" @change="onSearch" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="tenderStatus" placeholder="应标资格" @change="onSearch" clearable style="margin-left: 20px">
          <el-option v-for="item in tenders" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-autocomplete style="margin-left: 20px" v-model="period" :fetch-suggestions="getbidPeriodPagelist" clearable placeholder="请搜索标期" @select="onSearch" value-key="bidPeriod" @change="onSearch"></el-autocomplete>
        <!-- <el-select v-model="period" placeholder="请选择/搜索标期" @change="onSearch" clearable style="margin-left: 20px;" filterable remote :remote-method="getTenders_info_newBid_periods">
          <el-option v-for="item in listOfBidPeriods" :key="item" :label="item" :value="item">
          </el-option>
        </el-select> -->
        <el-input v-model="tendersNumber" placeholder="搜索标号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="tendersName" placeholder="搜索标书名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <!-- <el-input v-model="supplierName" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" :show-overflow-tooltip="true" width="50">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersLabel" label="标号" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersName" label="标书名称" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersType" label="招标类型" min-width="90">
            <template slot-scope="scope">
              <span>{{ scope.row.tendersType == 1 ? "商超" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格">
            <template slot-scope="scope">
              <span v-if="scope.row.eligibleType == 1">平台资格</span>
              <span v-if="scope.row.eligibleType == 2">独标资格</span>
            </template>
          </el-table-column>
          <el-table-column prop="effectiveEndTime" label="中标有效期" min-width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :title="`${scope.row.effectiveStartTime}至${scope.row.effectiveEndTime}`">{{ scope.row.effectiveStartTime }}至{{ scope.row.effectiveEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deadlinePayTime" label="保证金缴纳截止日期" min-width="150" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <span :title="`${scope.row.effectiveStartTime}至${scope.row.effectiveEndTime}`">{{ scope.row.effectiveStartTime }}至{{ scope.row.effectiveEndTime }}</span>
            </template> -->
          </el-table-column>

          <el-table-column prop="tendersNumber" label="最大投标数" min-width="100" align="center"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" min-width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="handleView(scope.row)" size="small">查看</el-button>
              <!-- <el-button class="btn" type="primary" @click="handleDate(scope.row)" size="small">修改缴费日期</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <el-dialog title="修改" :visible.sync="dialogVisible" width="60%" :before-close="onCancelEvaluationOfBids">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="250px" class="demo-ruleForm">
        <el-row>
          <el-col :span="50">
            <el-form-item label="履约保证金缴费截止日期：" prop="valueDate">
              <el-date-picker v-model="ruleForm.valueDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelEvaluationOfBids">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      valueDate: "",
      dialogVisible: false,
      content: "",
      loading: false,
      submitVisible: false,
      submitLoding: false,
      tenderStatus: "",
      period: "", //标期
      tendersName: "", //标书名称
      tendersNumber: "", //标号
      supplierName: "", //供应商
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      startStatus: "",
      bidPeriodlist: [],
      options: [
        {
          value: 0,
          label: "商超",
        },
      ],
      ruleForm: {
        valueDate: "",
        tendersId: "",
      },
      tenders: [
        {
          value: 1,
          label: "平台资格",
        },
        {
          value: 2,
          label: "独立标资格",
        },
      ],
      rules: {
        valueDate: [
          { required: true, message: "请选择日期", trigger: "change" },
          // { validator: validateTime, trigger: 'change' }
        ],
      },
      startDate: "",
      endDate: "",
      listOfBidPeriods: [],
      pickerOptions: {
        disabledDate: time => {
          return time < new Date(this.startDate) || time > new Date(this.endDate)
        },
      },
    }
  },

  created() {
     // 缓存页面的搜索条件
     let obj = JSON.parse(localStorage.getItem("productMark"))
    this.currentPage = obj?.currentPage
    this.startStatus = obj?.startStatus
    this.tenderStatus = obj?.tenderStatus
    this.period = obj?.period
    this.tendersNumber = obj?.tendersNumber
    this.tendersName = obj?.tendersName
    this.$saveOrUpdateCache("productMark", {})
    if (this.supplierName == "") {
      this.supplierName = localStorage.getItem("supplierName")
    }
    this.getportalPageList()
    this.getbidPeriodPagelist()
  },

  methods: {
    onCancelEvaluationOfBids() {
      this.$refs["ruleForm"].resetFields()
      this.dialogVisible = false
    },
    // 修改日期
    handleDate(row) {
      let data = Object.assign({}, row)
      this.dialogVisible = true
      this.startDate = data.effectiveStartTime
      this.endDate = data.effectiveEndTime
      this.ruleForm.valueDate = data.deadlinePayTime
      this.ruleForm.tendersId = data.tendersId

      // this.pickerOptions = {
      //   disabledDate:(time)=> {
      //     return time < new Date(this.startDate) || time > new Date(this.endDate);
      //   }
      // }
    },
    // 招标列表
    getportalPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.portalPageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            eligibleType: this.tenderStatus || null, //资格类型：1平台资格；2独标资格
            tendersLabel: this.tendersNumber || null, //标号
            tendersName: this.tendersName || null, //标书名称
            bidPeriod: this.period || null, //标期
            tendersType: this.startStatus || null, //招标类型(1:商超)
            supplierName: this.supplierName, //供应商名称
            selectType: 1,
          },
        })
        .then(res => {
          // console.log(res);
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //标期下拉列表
    getbidPeriodPagelist(queryString, cb) {
      this.bidPeriodloading = true
      this.$axios
        .get(this.$api.bidPeriodPage, {
          params: {
            page: 1,
            pageSize: 50,
            bidPeriod: queryString,
          },
        })
        .then(res => {
          this.bidPeriodloading = false
          this.bidPeriodlist = res.data.result.list
          cb(this.bidPeriodlist)
        })
    },

    onSearch() {
      if (this.supplierName == "") {
        localStorage.removeItem("supplierName")
      }
      this.currentPage = 1 // 搜索
      this.tableData = []
      // this.getbidPeriodPagelist()
      this.getportalPageList()
    },

    // 查看
    handleView(row) {
      let obj = {
        currentPage: this.currentPage,
        startStatus: this.startStatus,
        tenderStatus: this.tenderStatus,
        period: this.period,
        tendersNumber: this.tendersNumber,
        tendersName: this.tendersName,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productMark", obj)
      localStorage.setItem("supplierName", row.supplierName || "") //本地缓存标号
      this.$router.push({
        path: "/biddingManagement/detail/productMarkDetail",
        query: { tendersId: row.tendersId, identification: row.identification },
      })
    },

    // 不通过
    handleDelete(row) {
      this.submitVisible = true
      this.id = row.applicationId
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.deadlinePayTime, {
              deadlinePayTime: this.ruleForm.valueDate,
              tendersId: this.ruleForm.tendersId,
            })
            .then(res => {
              this.$message.success(res.data.desc)
              this.getportalPageList()
              this.onCancelEvaluationOfBids()
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        } else {
          // this.$message.error("提交失败");
          return false
        }
      })
    },
    // 通过
    // handlepPass(row) {
    //   this.$confirm("确定该供应商通过?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //     center: true,
    //   })
    //     .then(() => {
    //       this.$axios
    //         .post(this.$api.examine, {
    //           applicationId: row.applicationId,
    //           status: "1",
    //         })
    //         .then((res) => {
    //           if (res.data.code == 100) {
    //             this.$message.success(res.data.desc);
    //           }
    //           this.tenders_list_new();
    //         })
    //         .catch(() => { });
    //       // this.$message.success('确定该供应商通过')
    //     })
    //     .catch(() => { });
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.tableData = []
      this.getportalPageList()

      // this.onSearch();
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getportalPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .el-autocomplete,
    .el-select,
    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  // /* 设置表格单元格的最大宽度和省略显示 */
  // ::v-deep .el-table__row td .cell {
  //   max-width: 200px;
  //   /* 根据需要设置最大宽度 */
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
