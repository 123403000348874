<!-- 按品类设置 -->

<template>
  <div class="main">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="firstCategory" placeholder="请搜索一级类目" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="secondCategory" placeholder="请搜索二级类目" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleOpenDefaultDialog">管理默认设置</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed="left"></el-table-column>
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip label="一级类目"
            prop="firstCategoryName"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip label="二级类目"
            prop="secondCategoryName"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="第一次预警(天)" prop="firstWarnDay"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="secondWarnDay"
            label="第二次预警(天)"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleSet(scope.row)">设置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog title="设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <h2>预警设置</h2>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第一次预警：保质期剩余" prop="firstWarnDay">
              <el-input v-model="ruleForm.firstWarnDay" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第二次预警：保质期剩余" prop="secondWarnDay">
              <el-input v-model="ruleForm.secondWarnDay" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

    <defaultSettingDialog :dialogDefaultVisible.sync="dialogDefaultVisible"
      @handleCloseDefaultDialog="handleCloseDefaultDialog" ref="defaultDialog"
      @settingsUpdated="getExpireWarningListByCategory">
    </defaultSettingDialog>
  </div>
</template>

<script>
import defaultSettingDialog from './defaultSettingDialog.vue';
export default {
  components: {
    defaultSettingDialog
  },
  data() {
    return {
      dialogDefaultVisible: false,
      loading: false,
      firstCategory: '',
      secondCategory: '',
      // 表格数据
      tableData: [],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        firstWarnDay: null,
        secondWarnDay: null,

      },
      rules: {
        firstWarnDay: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        }
        ],
        secondWarnDay: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            const firstWarnDayValue = this.ruleForm.firstWarnDay;
            if (firstWarnDayValue && value && parseInt(value) > parseInt(firstWarnDayValue)) {
              callback(new Error('第二次预警天数不能大于第一次预警天数'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
        ]
      },

      multipleSelection: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getExpireWarningListByCategory();
  },
  methods: {
    // 获取列表
    getExpireWarningListByCategory() {
      this.loading = true;
      this.$axios.get(this.$api.getExpireWarningListByCategory, {
        params: {
          firstCategoryName: this.firstCategory || null,
          secondCategoryName: this.secondCategory || null,
          page: this.currentPage,
          pageSize: this.pageSize,
          bizType: 3, //  类型：1商品；2品牌；3分类
          bizUserType: 3, // 1商超；2供应商；3总后台
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    handleCloseDefaultDialog() {
      this.dialogDefaultVisible = false
    },
    handleOpenDefaultDialog() {
      this.dialogDefaultVisible = true;
      this.$refs.defaultDialog.defaultSettingSelectById();
    },
    // 选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('handleSelectionChange', val); // 触发事件，传递选中的值
    },
    // 打开设置
    handleSet(row) {
      this.dialogVisible = true;
      this.ruleForm = row;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.setExpireWarning, {
            bizIdList: [this.ruleForm.bizId],
            bizType: 3, // 类型：1商品；2品牌；3分类
            bizUserId: 0, // 业务id 总后台为0
            bizUserType: 3, // 1商超；2供应商；3总后台
            firstWarnDay: Number(this.ruleForm.firstWarnDay), // 第一天
            secondWarnDay: Number(this.ruleForm.secondWarnDay), // 第二天
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleCloseDialog();
              this.getExpireWarningListByCategory();
            }
          })
        }
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getExpireWarningListByCategory();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getExpireWarningListByCategory();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getExpireWarningListByCategory();
    },
  }

}
</script>

<style lang="scss" scoped>
.main {

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  .row-bottom {

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 255px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    ::v-deep .el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    ::v-deep .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}
</style>
