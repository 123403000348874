<!-- 自提详情 -->
<template>
  <div>
    <back />
    <div class="det" v-loading="loading">
      <!-- 基本信息 -->
      <div class="detail">
        <h4 class="title">详情信息：</h4>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">订单编号：{{ basicInfo.orderCode }}</div>
          </el-col>
          <el-col :span="4">
            <!-- <div class="col">支付类型：现金支付</div> -->
            <div class="col">支付类型：{{ getOrderTypeText(basicInfo.payType) }}支付</div>
          </el-col>
          <el-col :span="5">
            <div class="col">下单时间：{{ basicInfo.orderTime }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">销售金额：{{ Number(basicInfo.orderAmount).toFixed(2) }}元</div>
          </el-col>
          <el-col :span="4">
            <div class="col">实收金额：{{ Number(basicInfo.payAmount).toFixed(2) }}元</div>
          </el-col>

        </el-row>

      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <h4 class="title">标准商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData" max-height="540">
            <el-table-column label="序号" width="100">
              <template slot-scope="scope">
                <!-- {{ scope.$index + 1 + (currentPage - 1) * pageSize }}  -->
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="图片" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-image v-show="false" :preview-src-list="srcList" ref="previewImg"></el-image>
                <div style="color: #069BBC;cursor: pointer;" @click="handleCommand(scope.row.picUrl)">查看</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="specs" label="规格" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="brand" label="品牌" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="productPrice" label="售价(元)" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="productCount" label="购买数量" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="detail">
        <h4 class="title">非标商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData2" max-height="540">
            <el-table-column label="序号" width="100">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="productCode" label="商品编号" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="specsValue" label="重量/规格" show-overflow-tooltip min-width="120" align="center">
            </el-table-column>
            <el-table-column prop="productPrice" label="单价(元)" show-overflow-tooltip min-width="110" align="center">
            </el-table-column>
            <el-table-column prop="productCount" label="购买数量" show-overflow-tooltip min-width="100" align="center"
              fixed="right">
            </el-table-column>
          </el-table>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      basicInfo: {},//基本信息
      tableData: [],
      tableData2: [],
      orderId: '',//订单id
      srcList: [],
    }
  },

  created() {
    this.orderId = this.$route.query.orderId//订单id
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.ordersDet, {
        params: {
          orderId: this.orderId,//订单id
          shopId: this.$store.state.userInfo.shopId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.basicInfo = result
          this.tableData = result.selectOnlineOrdersProductVoList
          this.tableData2 = result.shopLabelProductDetailVoList
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '1':
          return '支付宝';
        case '2':
          return '微信';
        default:
          return '现金';
      }
    },
    // 点击按钮预览图片
    handleCommand(picUrl) {
      if (picUrl) {
        this.srcList = [picUrl];
        this.$refs.previewImg.showViewer = true;
      } else {
        this.$message.error("未上传商品图片!");
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .pagination {
      margin-bottom: 30px;
    }

  }



}
</style>