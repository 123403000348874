<template>
  <div class='list'>
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="status" clearable placeholder="全部状态" @change="onSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="search" placeholder="请输入商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="营业执照" width="110">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.businessLicenseUrl)">预览图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="creditCode" label="统一社会信用代码" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="schoolName" label="关联学校" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactMan" label="联系人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactTel" label="联系电话" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="商超地址" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.area }}{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="简介" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.introduction }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="status" label="审核状态">
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '审核通过' : scope.row.status == 2 ? '审核不通过' : '审核通过' }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="danger" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.status == 0">审核</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)" v-if="scope.row.status != 0">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="imgList"></el-image-viewer>

  </div>
</template>

<script>

export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
      loading: false,
      search: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '1',
        label: '审核通过'
      }, {
        value: '2',
        label: '审核不通过'
      }],
      status: '',

      // 图片预览
      showViewer: false,
      imgList: []
    }
  },
  created() {

    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("examine"))
    this.currentPage = obj?.currentPage
    this.status = obj?.status
    this.search = obj?.search
    this.$saveOrUpdateCache("examine", {})
    this.getSupplierInfoPageList()
  },
  methods: {
    // 商超列表
    getSupplierInfoPageList () {
      console.log(this.status);
      this.loading = true;
      this.$axios.get(this.$api.shopInfoPage, {
        params: {
          shopName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,
          status: this.status, // 审核状态 ( 0:待审核 1:审核通过 2:审核不通过 )
        },
      }).then((res) => {
        let list = res.data.result.list.map((e) => {
          return {
            ...e,
            switch: e.status == "1" ? true : false,
          };
        });
        this.tableData = list;
        this.totalItemsCount = res.data.result.totalCount;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },

    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e;
      this.getSupplierInfoPageList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e;
      this.getSupplierInfoPageList();
    },
    // 查看
    showDetail(row) {
      let obj = {
        search: this.search,
        status: this.status,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("examine", obj)
      this.$router.push({ path: '/supermarket/detail/info', query: { id: row.shopId }, });
    },
    // 图片预览
    previewImage (url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  },
}
</script>

<style scoped lang='scss'>
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      margin-left: 20px;
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>